import React, { useState, useEffect } from "react";
import {
  Input,
  Label,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import DataTable from "react-data-table-component";
import axios from "axios";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { toast } from "react-toastify";

const LoanMaster = () => {
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [filter, setFilter] = useState(true);
  const [modal_delete, setmodal_delete] = useState(false);
  const [remove_id, setRemove_id] = useState("");
  const [modal_edit, setmodal_edit] = useState(false);
  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [pageNo, setPageNo] = useState(0);
  const [column, setcolumn] = useState();
  const [sortDirection, setsortDirection] = useState();
  const [query, setQuery] = useState("");
  const [BGForm, setBGForm] = useState([]);

  const columns = [
    {
      name: "Serial Number",
      selector: (row, index) => index + 1,
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      sortField: "Name",
      minWidth: "150px",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      sortField: "Email",
      minWidth: "150px",
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
      sortField: "amount",
      minWidth: "150px",
    },
    {
      name: "Phone Number",
      selector: (row) => row.number,
      sortable: true,
      sortField: "number",
      minWidth: "150px",
    },
    {
      name: "Action",
      selector: (row) => {
        return (
          <React.Fragment>
            <div className="d-flex gap-2">
              <div className="edit">
                <button
                  className="btn btn-sm btn-success edit-item-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#showModal"
                  onClick={() => handleTog_edit(row)}
                >
                  View
                </button>
              </div>
              <div className="remove">
                <button
                  className="btn btn-sm btn-danger remove-item-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#deleteRecordModal"
                  onClick={() => tog_delete(row._id)}
                >
                  Remove
                </button>
              </div>
            </div>
          </React.Fragment>
        );
      },
      sortable: false,
      minWidth: "180px",
    },
  ];

  useEffect(() => {
    fetchLoans();
  }, [pageNo, perPage, column, sortDirection, query, filter]);

  const fetchLoans = async () => {
    try {
      setLoading(true);
      let skip = (pageNo - 1) * perPage;
      if (skip < 0) {
        skip = 0;
      }

      const defaultColumn = "loanId";
      const defaultSortDirection = "asc";

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_STONE}/api/auth/listbyparams/loan`,
        {
          skip: skip,
          per_page: perPage,
          sorton: column || defaultColumn,
          sortdir: sortDirection || defaultSortDirection,
          match: query,
        }
      );
      console.log(response);

      if (response[0].data && Array.isArray(response[0].data)) {
        setLoading(false);
        setBGForm(response[0].data);
        console.log("hello", response);

        setTotalRows(response[0].total);
      } else {
        console.error("Invalid response:", response);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const tog_delete = (_id) => {
    setmodal_delete(!modal_delete);
    setRemove_id(_id);
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL_STONE}/api/auth/deleteLoanById/${remove_id}`
      );
      toast.success("Loan record deleted successfully");
      setmodal_delete(!modal_delete);
      fetchLoans();
    } catch (err) {
      console.log(err);
      toast.error("Error deleting loan record");
    }
  };

  const handleTog_edit = (row) => {
    setmodal_edit(!modal_edit);
    setValues(row);
  };

  const handleSort = (column, sortDirection) => {
    setcolumn(column.sortField);
    setsortDirection(sortDirection);
  };

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
    setPageNo(page);
  };

  document.title = "StoneRealEstate | Loan Inquiry";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            maintitle="Marwiz Setup"
            title="Loan Inquiry"
            pageTitle="Loan Inquiry"
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-1">
                    <Col className="col-sm" lg={4} md={6} sm={6}>
                      <h2 className="card-title mb-0 fs-4 mt-2">Loan Inquiry</h2>
                    </Col>
                    <Col lg={4} md={6} sm={6}>
                      <div className="d-flex justify-content-sm-end">
                        <div className="search-box ms-2">
                          <input
                            className="form-control search"
                            placeholder="Search..."
                            onChange={(e) => setQuery(e.target.value)}
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="table-responsive table-card mt-1 mb-1 text-right">
                    <DataTable
                      columns={columns}
                      data={BGForm}
                      progressPending={loading}
                      sortServer
                      onSort={(column, sortDirection) =>
                        handleSort(column, sortDirection)
                      }
                      pagination
                      paginationServer
                      paginationTotalRows={totalRows}
                      paginationRowsPerPageOptions={[10, 50, 100, totalRows]}
                      onChangeRowsPerPage={handlePerRowsChange}
                      onChangePage={handlePageChange}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/*View Modal*/}
      <Modal
        isOpen={modal_edit}
        toggle={() => setmodal_edit(!modal_edit)}
        centered
      >
        <ModalHeader
          className="bg-light p-3"
          toggle={() => setmodal_edit(false)}
        >
          Loan Detail
        </ModalHeader>
        <ModalBody>
          <p>
            <span style={{ fontWeight: "bold" }}>Name : </span> {values.name}
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>Emial : </span> {values.email}
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>Amount: </span> {values.amount}
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>Applied For : </span>{" "}
            {values.subHeadings}
          </p>
        </ModalBody>
        <ModalFooter>
          <div className="hstack gap-2 justify-content-end">
            <button
              type="button"
              className="btn btn-outline-danger m-1"
              onClick={() => setmodal_edit(false)}
            >
              Close
            </button>
          </div>
        </ModalFooter>
      </Modal>

      {/*Remove Modal*/}
      <Modal
        isOpen={modal_delete}
        toggle={() => setmodal_delete(!modal_delete)}
        centered
      >
        <ModalHeader className="bg-light p-3">
          <span style={{ marginRight: "224px" }}>Remove Loan</span>
          <Button
            type="button"
            onClick={() => setmodal_delete(false)}
            className="btn-close"
            aria-label="Close"
          ></Button>
        </ModalHeader>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you sure?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you sure you want to remove this loan record?
              </p>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="hstack gap-2 justify-content-end">
            <button
              type="button"
              className="btn btn-danger"
              onClick={handleDelete}
            >
              Remove
            </button>
            <button
              type="button"
              className="btn btn-outline-danger"
              onClick={() => setmodal_delete(false)}
            >
              Close
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default LoanMaster;
