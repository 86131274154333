import React, { useState, useEffect } from "react";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { CardHeader,Button, Card, CardBody, Col, Container, Row, Table } from 'reactstrap';
import axios from "axios"; 
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {getAdminByEmail} from "../../functions/Profile/Profile";

const Profile = () => {
  const initialState={
    email: "",
    mobile: "",
    contactPerson: "",
    companyName: "",
    
  };
  const email = localStorage.getItem('admin');
      
  const [isSubmit, setIsSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [adminData, setAdminData] = useState(null); // State to store admin data
  const [modalEditOpen, setModalEditOpen] = useState(false); // State to manage modal open/close
  const [formData, setFormData] = useState({initialState});
  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log("no errors");
    }
  }, [formErrors, isSubmit]);

  useEffect(() => {
    // Fetch admin data from the backend API
    

    fetchAdminData(); // Call the function to fetch admin data
  }, []); // Run this effect only once on component mount
  const storedEmail = JSON.parse(localStorage.getItem("admin"));
  const cleanedEmail = storedEmail.replace(/^"(.*)"$/, '$1');
  
  // Use cleanedEmail in your API call
  const fetchAdminData = async () => {
    try {
      console.log(cleanedEmail); // Log cleanedEmail to verify it doesn't contain quotes
      const response = await axios.get(`${process.env.REACT_APP_API_URL_STONE}/api/get/admin/${cleanedEmail}`);
      console.log(response);
      setAdminData(response); // Set admin data in state
    } catch (error) {
      console.error("Error fetching admin data:", error);
    }
  };
  const toggleModalEdit = () => {
    setModalEditOpen(!modalEditOpen);
    
    setErrEditEmail(false);
    seterrEditCompanyName(false);
    setIsSubmit(false);
    console.log("email", cleanedEmail)
    getAdminByEmail(cleanedEmail).then((res)=>{
      console.log("get",res.email);
      setFormData({
        ...formData,
        email:res.email,
        contactPerson:res.contactPerson,
        companyName:res.companyName,
        mobile:res.mobile
      })
    })
    .catch((err) => {
      console.log(err);
    });
    };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };


  const handleUpdate = async (e) => {
  
    e.preventDefault();
    console.log(formData)
    console.log(formData.email)
    let errors = validateEdit(formData);
    setFormErrors(errors);
    setIsSubmit(true);
    console.log(adminData)
    if(Object.keys(errors).length === 0){
      const response = await axios.put(`${process.env.REACT_APP_API_URL_STONE}/api/auth/edit/admin/${adminData.email}`, formData).then((res)=>{
        if(res.success){
          console.log(response.data); 
          setFormData(initialState);
          fetchAdminData();
      // Close the modal
      toggleModalEdit();
        }
        else{ // if (res.field === 1) {
          //   setFormErrors({ serviceCode: res.message });
          //   setErrEditServiceCode(true);
          // }
          // if (res.field === 1) {
          //   setFormErrors({ plot_no: res.message });
          //   setErrEditQuotationService(true);
          // }}
        
      }
      fetchAdminData();
      // Close the modal
      toggleModalEdit();
    }) .catch((err) => {
        console.log(err);
      });
      // Log response from the server
      
    }
    // try {
    //   // Make API call to update user profile
      
    //   // Update adminData state with the updated data
     
    // } catch (error) {
    //   console.error("Error updating profile:", error);
    // }
  };
  
  const [errEditEmail, setErrEditEmail] = useState(false);
  const [errEditCompanyName , seterrEditCompanyName] = useState(false);
  const [errEditContactPerson , seterrEditContactPerson] =useState(false);
  const [errEditMobile , seterrEditMobile]=useState(false);
  const validClassEditEmail =
  errEditEmail && isSubmit
    ? "form-control is-invalid"
    : "form-control";
    const validClassEditCompanyName =
    errEditCompanyName && isSubmit
      ? "form-control is-invalid"
      : "form-control";
  const validClassEditContactPerson =
  errEditContactPerson && isSubmit
    ? "form-control is-invalid"
    : "form-control"; 
  const validClassEditMobile =
  errEditMobile && isSubmit
  ? "form-control is-invalid"
  : "form-control"; 


 const validateEdit = (values) => {
  console.log("value",values.email)
    const errors = {};
    // console.log(values.plot_no);
//     if (!/^[a-zA-Z][\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(values.email)) {
//       errors.email = "Invalid email address";
//       setErrEditEmail(true);
//   }
//     if (values.email === "") {
//       errors.email = "email   is required";
//       setErrEditEmail(true);
//     } else {
//       setErrEditEmail(false);
//     }
    if (!/^[a-zA-Z\s]+$/.test(values.companyName)) {
      // Check if the contact person contains characters and spaces only
      errors.companyName = "Contact Person must contain only letters and spaces";
      seterrEditContactPerson(true);
    }
    if(values.companyName ===""){
      errors.companyName ="Company name is required";
      seterrEditCompanyName(true);
    }
    else{seterrEditCompanyName(false);}
    if (!/^[a-zA-Z\s]+$/.test(values.contactPerson)) {
      // Check if the contact person contains characters and spaces only
      errors.contactPerson = "Contact Person must contain only letters and spaces";
      seterrEditContactPerson(true);
    }
    if(values.contactPerson ===""){
      errors.contactPerson ="Contact Person is required";
      seterrEditContactPerson(true);
    }
    else{seterrEditContactPerson(false);}
    if (!/^\d{10}$/.test(values.mobile)) {
      // Check if the mobile number does not consist of 10 digits
      errors.mobile = "Mobile number must be a 10-digit number";
      seterrEditMobile(true);}
    if(values.mobile ===""){
      errors.mobile ="Mobile is required";
      seterrEditMobile(true);
    }
    
    else{seterrEditMobile(false);}



    return errors;
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <BreadCrumb maintitle="Marwiz Setup" pageTitle="Profile" />

          <Row>
          <CardHeader>
                  {/* <div className="h4 mb-0">Manage Quotation Services</div> */}
                  <Row className="g-4 mb-1">
                    <Col className="col-sm" lg={4} md={6} sm={6}>
                      <h2 className="card-title mb-0 fs-4 mt-2">
                        Profile Detail
                      </h2>
                    </Col>
                    <Col lg={4} md={6} sm={6}>
                      {/* <div className="text-end mt-1">
                        <Input
                          type="checkbox"
                          className="form-check-input"
                          name="filter"
                          value={filter}
                          defaultChecked={true}
                          onChange={handleFilter}
                        />
                        <Label className="form-check-label ms-2">Active</Label>
                      </div> */}
                    </Col>
                    <Col className="col-sm-auto" lg={4} md={12} sm={12}>
                      <div className="d-flex justify-content-sm-end">
                      <div className="edit">
                      <button
                        className="btn btn-sm btn-success edit-item-btn"
                        onClick={toggleModalEdit}
                      >
                        Edit
                      </button>
              </div>
                      
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <h5 className="card-title mb-3">Admin Info</h5>
                  {adminData && (
                    <div className="table-responsive">
                      <Table className="table-borderless mb-0">
                        <tbody>
                          <tr>
                            <th className="ps-0" scope="row">Email:</th>
                            <td className="text-muted">{adminData.email}</td>
                          </tr>
                          <tr>
                            <th className="ps-0" scope="row">Company Name</th>
                            <td className="text-muted">{adminData.companyName}</td>
                          </tr>
                          <tr>
                            <th className="ps-0" scope="row">Contact Person</th>
                            <td className="text-muted">{adminData.contactPerson}</td>
                          </tr>
                          <tr>
                            <th className="ps-0" scope="row">Mobile</th>
                            <td className="text-muted">{adminData.mobile}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  )}
                </CardBody>

              </Card>
            </Col>
          </Row>
          <Modal isOpen={modalEditOpen} toggle={toggleModalEdit} centered>
            <ModalHeader  className="bg-light p-3" toggle={toggleModalEdit}>Edit Profile</ModalHeader>
            <form >
              <ModalBody>
              <div className="mb-3 form-floating ">
              <input
                    type="text"
                    placeholder="email"
                    required
                    className={validClassEditEmail}
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    disabled
                  />
                  {isSubmit && (
                <p className="text-danger">{formErrors.email}</p>
              )}
              <label htmlFor="role-field" className="form-label">
            Email Id
                <span className="text-danger">*</span>
              </label>

              </div>

              <div className="mb-3 form-floating ">
                  <input
                    type="text"
                    placeholder="Company Name"
                  
                    className={validClassEditCompanyName}
                    name="companyName"
                    value={formData.companyName}
                    onChange={handleChange}
                  />
 {isSubmit && (
                <p className="text-danger">{formErrors.companyName}</p>
              )}
              <label htmlFor="role-field" className="form-label">
          Company Name
                <span className="text-danger">*</span>
              </label>
                  </div>
                  <div className="mb-3 form-floating ">
                  <input
                    type="text"
                    placeholder="Contact Person"
                    className={validClassEditContactPerson}
                    name="contactPerson"
                    value={formData.contactPerson}
                    onChange={handleChange}
                  />
                   {isSubmit && (
                <p className="text-danger">{formErrors.contactPerson}</p>
              )}
              <label htmlFor="role-field" className="form-label">
            Contact Person
                <span className="text-danger">*</span>
              </label>
                  </div>

                  <div className="mb-3 form-floating ">
                  <input
                    type="text"
                    placeholder="Mobile"
                    className={validClassEditMobile}
                    name="mobile"
                    value={formData.mobile}
                    onChange={handleChange}
                  />
                   {isSubmit && (
                <p className="text-danger">{formErrors.mobile}</p>
              )}
              <label htmlFor="role-field" className="form-label">
           Mobile
                <span className="text-danger">*</span>
              </label>
           </div>
              </ModalBody>
              <ModalFooter>
                <Button color="success" onClick={handleUpdate} type="submit">Update</Button>{' '}
                <Button color="secondary" onClick={toggleModalEdit}>Cancel</Button>
              </ModalFooter>
            </form>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Profile;