import axios from "axios";

export const createOurDivison = async (values) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL_STONE}/api/auth/create/OurDivison`,
    values
  );
};

export const removeOurDivison = async (_id) => {
  return await axios.delete(
    `${process.env.REACT_APP_API_URL_STONE}/api/auth/remove/OurDivison/${_id}`
  );
};

export const listOurDivison = async () => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_STONE}/api/auth/list/OurDivison`
  );
};

export const updateOurDivison = async (_id, values) => {
  return await axios.put(
    `${process.env.REACT_APP_API_URL_STONE}/api/auth/update/OurDivison/${_id}`,
    values
  );
};

export const getOurDivison = async (_id) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_STONE}/api/auth/get/OurDivison/${_id}`
  );
};
