import React, { useState, useEffect } from "react";
import {
  Input,
  Label,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import Select from "react-select";
import {
  updateProject,getProjectById,deleteProject,creatProject
 } from "../../functions/Projects";
 import axios from "axios";  
 import BreadCrumb from '../../Components/Common/BreadCrumb';
import DataTable from "react-data-table-component";
import { toast, ToastContainer } from "react-toastify";
import { Table } from "antd";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";


// Import React FilePond
import { FilePond,  registerPlugin } from 'react-filepond';
// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);


const Project = () => {
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [filter, setFilter] = useState(true);
  const [_id, set_Id] = useState("");

  const initialState = {
    ProjectTitle:"",
    MainImage:"",
    MultipleImage:"",
    ServiceName: "",
    isActive:false,
    Desc:"",
    Image:""
  };

  const [remove_id, setRemove_id] = useState("");

  //search and pagination state
  const [query, setQuery] = useState("");

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [values, setValues] = useState(initialState);
  const {
    ProjectTitle,MultipleImage,
     ServiceName 
     ,isActive,Desc,MainImage
   
  } = values;

  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [pageNo, setPageNo] = useState(0);
  const [column, setcolumn] = useState();
  const [sortDirection, setsortDirection] = useState();
  const [image, setImage] = useState("");
  const [BGForm, setBGForm] = useState([]);
  const renderImage = (Image) => {
    const imageUrl = `${process.env.REACT_APP_API_URL_STONE}/${Image}`;
    return (
      <img
        src={imageUrl}
        alt="Image"
        style={{width:'75px',height:'75px',padding:"5px"}}
      />
    );
  };
  const columns = [
    {
      name: "Process ID",
      selector: (row, index) => index + 1, // Generate auto-incremental ID based on row index
      sortable: true,
      minWidth: "150px",
    },
    {
        name: "ProjectTitle ",
        selector: (row) => row.ProjectTitle,
        sortable: true,
        sortField: "ProjectTitle",
        minWidth: "150px",
      },
    {
      name: "ServiceName ",
      selector: (row) => row.ServiceName,
      sortable: true,
      sortField: "ServiceName",
      minWidth: "150px",
    },
    {
      name: "Desc ",
      selector: (row) => row.Desc,
      sortable: true,
      sortField: "Desc",
      minWidth: "150px",
    },
    {
        
        name: "MAinImage",
        selector: (row) => renderImage(row.MainImage), // Generate auto-incremental ID based on row index
        sortable: true,
        minWidth: "150px",
      },
      {
        
        name: "MultipleImage",
        selector: (row) => row.MultipleImage, // Generate auto-incremental ID based on row index
        sortable: true,
        minWidth: "150px",
      },
    {
      name: "isActive",
      selector: (row) => (
        <span className={row.isActive ? "badge bg-success" : "badge bg-danger"}>
          {row.isActive ? "Active" : "Inactive"}
        </span>
      ),
      minWidth: "100px",
    },
    
    {
      name: "Action",
      selector: (row) => {
        return (
          <React.Fragment>
            <div className="d-flex gap-2">
              <div className="edit">
                <button
                  className="btn btn-sm btn-success edit-item-btn "
                  data-bs-toggle="modal"
                  data-bs-target="#showModal"
                  onClick={() => handleTog_edit(row._id)}
                >
                  Edit
                </button>
              </div>
              <div className="remove">
                <button
                  className="btn btn-sm btn-danger remove-item-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#deleteRecordModal"
                  onClick={() => tog_delete(row._id)}
                >
                  Remove
                </button>
              </div>
            </div>
          </React.Fragment>
        );
      },
      sortable: false,
      minWidth: "180px",
    },
  ];
  


  useEffect(() => {
    fetchTeamRoles();
  }, [pageNo, perPage, column, sortDirection, query, filter]);
  const [ServiceData, setServiceData] = useState([]);

  
  const fetchServices = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_STONE}/api/list/getAllService`
      );

      console.log("My response", response.data);
      const data = response.map((service) => ({
        label: service.ServiceName,
        value: service._id,
        name: service.ServiceName,
      }));
      
      
      setServiceData(data);
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  const handleServiceChange = (selectedOption) => {
    console.log("Selected service:", selectedOption.name);
    // Do something with the selected service name
    setValues({ ...values, ServiceName: selectedOption.name });
  };
  

  

  const fetchTeamRoles = async () => {
    try {
      setLoading(true);
      let skip = (pageNo - 1) * perPage;
      if (skip < 0) {
        skip = 0;
      }

      // Initialize column and sortDirection with default values
      const defaultColumn = "ServiceName"; // Change this to your default column
      const defaultSortDirection = "asc"; // Change this to your default sort direction

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_STONE}/api/list/getAllProjects`,
        {
          skip: skip,
          per_page: perPage,
          sorton: column || defaultColumn, // Use column or defaultColumn if column is undefined
          sortdir: sortDirection || defaultSortDirection, // Use sortDirection or defaultSortDirection if sortDirection is undefined
          match: query,
        }
      );

      console.log("Response:", response);

      console.log(Array.isArray(response));

      if (Array.isArray(response)) {
        setLoading(false);

        // Extract data for the current page
        const startIndex = skip;
        const endIndex = startIndex + perPage;
        const paginatedData = response.slice(startIndex, endIndex);

        setBGForm(paginatedData);
        setTotalRows(response.length);
      } else {
        // Handle non-200 status code or non-array data
        console.error("Invalid response:", response);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  const [modal_delete, setmodal_delete] = useState(false);

  const tog_delete = (_id) => {
    setmodal_delete(!modal_delete);
    setRemove_id(_id);
  };

  const [modal_edit, setmodal_edit] = useState(false);

  // const handlecheck = (e) => {
  //   console.log(e.target.checked);
  //   setValues({ ...values, IsActive: e.target.checked });
  // };

  const [modal_list, setModalList] = useState(false);

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log("no errors");
    }
  }, [formErrors, isSubmit]);

  // const loadBGForm = () => {
  //   listQuotation().then((res) => setBGForm(res));
  // };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleClick = async (e) => {
    e.preventDefault();
    let errors;
    values.MainImage = profilePhoto;
    console.log("image to upload is", values.MainImage);
    if (!values.MainImage) {
      toast.error("Please upload an image");
      return;
      // Prevent form submission
    }
    try {
        console.log("before error",values)
      // Validate the form values
      errors = validate(values);

      // Set form errors and indicate submission
      setFormErrors(errors);
      setIsSubmit(true);
    console.log("after error",values)
      if (Object.keys(errors).length === 0) {
        try {
          
          const formData=new FormData();
          formData.append("ProjectTitle",values.ProjectTitle);
          formData.append("MultipleImage",values.MultipleImage);
          formData.append("ServiceName",values.ServiceName);
          formData.append("Desc",values.Desc);
          formData.append("MainImage",values.MainImage);
          formData.append("isActive",values.isActive);
          console.log("inner try",formData )
        //   Make the API request
          const response = await creatProject(formData);
           
          if (response.isOk) {
            fetchTeamRoles();
            // Operation successful
            // setValues(initialState);
            setIsSubmit(false);
            setFormErrors({});
           
            setModalList(false);
            
            
          } else {
            // Operation failed
            // if (response.field === 1) {
            //   setFormErrors({ ServiceName: response.message });
            // }
            // fetchTeamRoles();
          }
        } catch (error) {
          console.error("API Request Error:", error);
          setIsSubmit(false);
         
        }
        fetchTeamRoles();setModalList(false);
      }
    } catch (error) {
      console.error("Handle Click Error:", error);
    }
  };

  // Add this function to your component
  const tog_list = () => {
    fetchServices();
    setModalList(!modal_list);
    setValues(initialState);
    setIsSubmit(false);
  };

  const handleDelete = (e) => {
    e.preventDefault();
    console.log("Quotation services", remove_id);
    deleteProject(remove_id)
      .then((res) => {
        console.log("deleted", res);
        setmodal_delete(!modal_delete);
        fetchTeamRoles();
      })
      .catch((err) => {
        console.log(err);
      });
  };

const handleUpdate =  async (e) => {
  e.preventDefault();
  console.log(values);
 
  try{
    let errors = validateEdit(values);
  setFormErrors(errors);
  setIsSubmit(true);

  if (Object.keys(errors).length === 0) {
    try{
        const response= await updateProject(_id, values);
        if(response.isOk){
            console.log(response);
            console.log("mod", modal_edit);
            fetchTeamRoles();  
            // setValues(initialState);
            setFormErrors({}); setmodal_edit(false);
        }
        else{
            // Operation failed
        }
     } 
     catch(error) 
     {
            console.log(error);
     }
          fetchTeamRoles();setmodal_edit(false);
    }}
    catch (error) {
        console.error("Handle Click Error:", error);
      }
    }
     
  const handleTog_edit = (_id) => {
    setmodal_edit(!modal_edit);
    fetchServices();
    setIsSubmit(false);
    set_Id(_id);
    console.log(_id);
    setErrEditQuotationService(false);
    seterrEditDesc(false)
    setErrEditServiceDesc(false);
    setErrEditServiceCode(false);
    setErrEditServiceRate(false);
    getProjectById(_id)
      .then((res) => {
        console.log("get", res);
        // console.log(res.plot_no);
        setValues({
           
            ...values,
          ProjectTitle:res.project.ProjectTitle,
          MultipleImage:res.project.MultipleImage,
          ServiceName :res.project.ServiceName,
          isActive:res.project.isActive,
          Desc:res.project.Desc,
          MainImage:res.project.MainImage,
           // Add buyer mobile number
          // Add additional fields here if needed
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
 
  const handleSort = (column, sortDirection) => {
    setcolumn(column.sortField);
    setsortDirection(sortDirection);
  };

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    // setPageNo(page);
    setPerPage(newPerPage);
  };
  const[errPT ,seterrPT] = useState(false);

  const [errSN, setErrSN] = useState(false);
  const [errSI ,seterrSI] = useState(false);
  const[errMI,seterrMI]=useState(false);
  const [errDE,seterrDE]= useState(false);
  const [errIA, seterrIA] = useState(false);
  const [errSC, setErrSC] = useState(false);
  const [errSR, setErrSR] = useState(false);
  const [errSD, setErrSD] = useState(false);
  const [errLength, setErrLength] = useState(false);
  const [errwidth, setErrwidth] = useState(false);
  const [errActive, setErrActive] = useState(false);
  const [errBuyerName, setErrBuyerName] = useState(false);
  const [errBuyerEmail, setErrBuyerEmail] = useState(false);
  const [errBuyerMobileNo, setErrBuyerMobileNo] = useState(false);

  const validate = (values) => {
    const errors = {};
    if (values.ProjectTitle === "") {
        errors.ProjectTitle = "ServiceName is required";
        seterrPT(true);
      }
    
    if (values.MainImage === "") {
        errors.MainImage = "ServiceName is required";
        seterrSI(true);
      }
      if (values.MultipleImage === "") {
        errors.MultipleImage = "ServiceName is required";
        seterrMI(true);
      }
      if (values.Desc === "") {
        errors.Desc = "ServiceName is required";
        seterrDE(true);
      }
      if (values.isActive === "") {
        errors.isActive = "ServiceName is required";
        seterrIA(true);
      }

    
   

    return errors;
  };

  const [errEditQuotationService, setErrEditQuotationService] = useState(false);
  const [errEditDesc , seterrEditDesc] =useState(false);
  const [errEditImage,seterrEditImage]=useState(false);
  const [errEditMultipleImage,seterrEditMultipleImage] =useState(false);
  const [errEditServiceDesc, setErrEditServiceDesc] = useState(false);
  const [errEditServiceCode, setErrEditServiceCode] = useState(false);
  const [errEditServiceRate, setErrEditServiceRate] = useState(false);
  const [errEditIsActive, setErrIsActive] = useState(false);
  const [errEditBuyerName, setErrEditBuyerName] = useState(false);
  const [errEditBuyerEmail, setErrEditBuyerEmail] = useState(false);
  const [errEditBuyerMobileNo, setErrEditBuyerMobileNo] = useState(false);

  const validateEdit = (values) => {
    const errors = {};
    console.log(values.ServiceName);
    if (values.ServiceName === "") {
      errors.ServiceName = "ServiceName is required";
      setErrEditQuotationService(true);
    } else {
      setErrEditQuotationService(false);
    }
    if (values.Desc === "") {
        errors.Desc = "ServiceName is required";
        seterrEditDesc(true);
      } else {
        seterrEditDesc(false);
      }
      if (values.MainImage === "") {
        errors.MainImage = "ServiceName is required";
        seterrEditImage(true);
      } else {
        seterrEditImage(false);
      }
      if (values.MultipleImage === "") {
        errors.MultipleImage = "ServiceName is required";
        seterrEditMultipleImage(true);
      } else {
        seterrEditMultipleImage(false);
      }

    

   

    return errors;
  };
  const validClassProjectTitle =
    errPT && isSubmit ? "form-control is-invalid" : "form-control";
  const validClassServiceName =
    errSN && isSubmit ? "form-control is-invalid" : "form-control";
    const validClassImage =
    errSI && isSubmit ? "form-control is-invalid" : "form-control";
    const validClassMultipleImage =
    errMI && isSubmit ? "form-control is-invalid" : "form-control";
    const validClassDesc =
    errDE && isSubmit ? "form-control is-invalid" : "form-control";
    const validClassIsActive =
    errIA && isSubmit ? "form-control is-invalid" : "form-control";
        
  const validClassServiceCode =
    errSC && isSubmit ? "form-control is-invalid" : "form-control";
  const validClassServiceDescription =
    errSD && isSubmit ? "form-control is-invalid" : "form-control";
  const validClassLength =
    errLength && isSubmit ? "form-control is-invalid" : "form-control";
  const validClassWidth =
    errwidth && isSubmit ? "form-control is-invalid" : "form-control";
  const validClassBuyerName =
    errBuyerName && isSubmit ? "form-control is-invalid" : "form-control";
  const validClassEmail =
    errBuyerEmail && isSubmit ? "form-control is-invalid" : "form-control";
  const validClassNumber =
    errBuyerMobileNo && isSubmit ? "form-control is-invalid" : "form-control";
  const validClassServiceRate =
    errSR && isSubmit ? "form-control is-invalid" : "form-control";
  const validClassActive =
    errActive && isSubmit ? "form-check-input is-invalid" : "form-check-input";

  const validClassEditServiceName =
    errEditQuotationService && isSubmit
      ? "form-control is-invalid"
      : "form-control";

      const validClassEditServiceDescription =
      errEditDesc && isSubmit
        ? "form-control is-invalid"
        : "form-control";
        const validClassEditImage =
        errEditImage && isSubmit
          ? "form-control is-invalid"
          : "form-control";
  
  const validClassEditServiceCode =
    errEditServiceCode && isSubmit ? "form-control is-invalid" : "form-control";
   const validClassEditServiceRate =
    errEditServiceRate && isSubmit ? "form-control is-invalid" : "form-control";
  const validClassEditName =
    errEditBuyerName && isSubmit ? "form-control is-invalid" : "form-control";
  const validClassEditEmail =
    errEditBuyerEmail && isSubmit ? "form-control is-invalid" : "form-control";
  const validClassEditNumber =
    errEditBuyerMobileNo && isSubmit
      ? "form-control is-invalid"
      : "form-control";
  // const validClassEditActive =
  //   errActive && isSubmit ? "form-check-input is-invalid" : "form-check-input";
  const [selectedFiles, setselectedFiles] = useState([]);
  const [files, setFiles] = useState([]);

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  const handleFilter = (e) => {
    setFilter(e.target.checked);
  };
  const [profilePhoto, setProfilePhoto] = useState("");
  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    console.log(file);
    setProfilePhoto(file);
  };
   const handleImageChange = (e) => {
    const file = e.target.files[0];
    console.log(file);
    setImage(file);
     
  };
  document.title = "Visa-Categories | Visa";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <BreadCrumb
            maintitle="Marwiz Setup"
            title="Project Master"
            pageTitle="Project Master"
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  {/* <div className="h4 mb-0">Manage Quotation Services</div> */}
                  <Row className="g-4 mb-1">
                    <Col className="col-sm" lg={4} md={6} sm={6}>
                      <h2 className="card-title mb-0 fs-4 mt-2">Project Master</h2>
                    </Col>
                    <Col lg={4} md={6} sm={6}>
                      {/* <div className="text-end mt-1">
                        <Input
                          type="checkbox"
                          className="form-check-input"
                          name="filter"
                          value={filter}
                          defaultChecked={true}
                          onChange={handleFilter}
                        />
                        <Label className="form-check-label ms-2">Active</Label>
                      </div> */}
                    </Col>
                    <Col className="col-sm-auto" lg={4} md={12} sm={12}>
                      <div className="d-flex justify-content-sm-end">
                        <div>
                          <Button
                            color="success"
                            className="add-btn me-1"
                            onClick={() => tog_list()}
                            id="create-btn"
                          >
                            <i className="ri-add-line align-bottom me-1"></i>
                            Add
                          </Button>
                        </div>
                        <div className="search-box ms-2">
                          <input
                            // type="text"
                            className="form-control search"
                            placeholder="Search..."
                            onChange={(e) => setQuery(e.target.value)}
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div>
                    <div className="table-responsive table-card mt-1 mb-1 text-right">
                     
                       <DataTable
                        columns={columns}
                        data={BGForm}
                        progressPending={loading}
                        sortServer
                        // onRowClicked={(row,e)=>{
                        //   debugger
                        // }}
                        onSort={(column, sortDirection, sortedRows) => {
                          handleSort(column, sortDirection);
                        }}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        paginationRowsPerPageOptions={[10, 50, 100, totalRows]}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                      />
                    </div>
                  </div>
                </CardBody>
               
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
      >
        <ModalHeader
          className="bg-light p-3"
          toggle={() => {
            setModalList(false);
          }}
        >
          Add Project
          {/* <Button
            type="button"
            onClick={() => {
              setmodal_list(false);
            }}
            className="btn-close"
            aria-label="Close"
          ></Button> */}
        </ModalHeader>
        <form>
        <ModalBody>
     
        <div className="form-floating mb-3">
    <input
      type="text"
      className={validClassProjectTitle}
      placeholder="Service Name"
      required
      name="ProjectTitle"
      value={ProjectTitle}
      onChange={handleChange}
    />
    {isSubmit && (
      <p className="text-danger">{formErrors.ProjectTitle}</p>
    )}
    <label htmlFor="service-name" className="form-label">
    Project Title
      <span className="text-danger">*</span>
    </label>
  </div>
  <label htmlFor="service-name" className="form-label">
   Service Name
      <span className="text-danger">*</span>
    </label>
  <div className="form-floating mb-3">
    {/* <input
      type="text"
      className={validClassServiceName}
      placeholder="Service Name"
      required
      name="ServiceName"
      value={ServiceName}
      onChange={handleChange}
    /> */}
  
     <Select
     placeholder={ServiceName}
    id="ServiceName"
    value={ServiceName}
    options={ServiceData}
    onChange={handleServiceChange}
  />
     
     
  </div>
  <div className="form-floating mb-3">
            <Label
              for="profile-photo"
              className="form-label mt-3"
            >
            Main Image 
            </Label>
            <input
              type="file"
              className="form-control"
              id="MainImage"
              name="MainImage"
              accept=".jpg, .jpeg, .png"
              onChange={handlePhotoChange}
            />
          </div>
  {/* <div className="form-floating mb-3">
    <input
      type="text"
      className={validClassImage}
      placeholder="Image URL"
      required
      name="MainImage"
      value={MainImage}
      onChange={handleChange}
    />
    {isSubmit && (
      <p className="text-danger">{formErrors.MainImage}</p>
    )}
    <label htmlFor="image" className="form-label">
      MAin Image URL
      <span className="text-danger">*</span>
    </label>
  </div> */}

  <div className="form-floating mb-3">
    <input
      type="text"
      className={validClassMultipleImage}
      placeholder="Image URL"
      required
      name="MultipleImage"
      value={MultipleImage}
      onChange={handleChange}
    />
    {isSubmit && (
      <p className="text-danger">{formErrors.MultipleImage}</p>
    )}
    <label htmlFor="image" className="form-label">
      Multiple Image URL
      <span className="text-danger">*</span>
    </label>
  </div>
  <div className="form-floating mb-3">
    <input
      type="text"
      className={validClassDesc}
      placeholder="Description"
      required
      name="Desc"
      value={Desc}
      onChange={handleChange}
    />
    {isSubmit && (
      <p className="text-danger">{formErrors.Desc}</p>
    )}
    <label htmlFor="description" className="form-label">
      Description
      <span className="text-danger">*</span>
    </label>
  </div>
  <div className="mt-3">
                                    <Input
                                      type="checkbox"
                                      id="isActive"
                                      label="Is Active"
                                      name="isActive"
                                      value={isActive}
                                      onChange={(e) => setValues({ ...values, isActive: e.target.checked })}
                                      checked={isActive}
                                    />
                                    <label className="me-2">Is Active</label>
                                  </div>
  {/* <div className="form-check form-switch"> */}
    {/* <input
      className={validClassIsActive}
      type="checkbox"
      id="isActive"
      name="isActive"
      checked={isActive}
      onChange={(e) => setValues({ ...values, isActive: e.target.checked })}
    /> */}
    {/* {isSubmit && (
      <p className="text-danger">{formErrors.isActive}</p>
    )}
    <label className="form-check-label" htmlFor="isActive">
      Is Active
    </label>
  </div> */}
</ModalBody>

          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="submit"
                className="btn btn-success  m-1"
                id="add-btn"
                onClick={handleClick}
              >
                Submit
              </button>
              <button
                type="button"
                className="btn btn-outline-danger m-1"
                onClick={() => setModalList(false)}
              >
                Cancel
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>

      {/*Edit Modal*/}
      <Modal
        isOpen={modal_edit}
        toggle={() => {
          handleTog_edit();
        }}
        centered
      >
        <ModalHeader
          className="bg-light p-3"
          toggle={() => {
            setmodal_edit(false);
          }}
        >
          Edit Services
        </ModalHeader>
        <form>
        <ModalBody>
        <div className="mb-3 form-floating ">
              <input
                type="text"
                className={validClassEditServiceName}
                placeholder="Enter Billing Service"
                required
                name="ProjectTitle"
                value={ProjectTitle}
                onChange={handleChange}
              />
              {isSubmit && <p className="text-danger">{formErrors.ProjectTitle}</p>}
              <label htmlFor="role-field" className="form-label">
              ProjectTitle
                <span className="text-danger">*</span>
              </label>
            </div>
            <label htmlFor="service-name" className="form-label">
   Service Name
      <span className="text-danger">*</span>
    </label>
  <div className="form-floating mb-3">
    {/* <input
      type="text"
      className={validClassServiceName}
      placeholder="Service Name"
      required
      name="ServiceName"
      value={ServiceName}
      onChange={handleChange}
    /> */}
  
     <Select
     placeholder={ServiceName}
    id="ServiceName"
    value={ServiceName}
    options={ServiceData}
    onChange={handleServiceChange}
  />
     
     
  </div>
  <div className="mb-3 form-floating">
    <input
      type="text"
      className={validClassEditImage}
      placeholder="Enter Image URL"
      required
      name="MainImage"
      value={MainImage}
      onChange={handleChange}
    />
    {isSubmit && <p className="text-danger">{formErrors.MainImage}</p>}
    <label htmlFor="image-field" className="form-label">
      Image
      <span className="text-danger">*</span>
    </label>
  </div>
  <div className="mb-3 form-floating">
    <input
      type="text"
      className={validClassEditImage}
      placeholder="Enter Image URL"
      required
      name="MultipleImage"
      value={MultipleImage}
      onChange={handleChange}
    />
    {isSubmit && <p className="text-danger">{formErrors.MultipleImage}</p>}
    <label htmlFor="image-field" className="form-label">
      Image
      <span className="text-danger">*</span>
    </label>
  </div>
  <div className="mb-3 form-floating">
    <textarea
      className={validClassEditServiceDescription}
      placeholder="Enter Description"
      required
      name="Desc"
      value={Desc}
      onChange={handleChange}
    />
    {isSubmit && <p className="text-danger">{formErrors.Desc}</p>}
    <label htmlFor="desc-field" className="form-label">
      Description
      <span className="text-danger">*</span>
    </label>
  </div>
  <div className="mb-3 form-check">
  <input
    type="checkbox"
    className="form-check-input"
    id="isActive"
    name="isActive"
    checked={values.isActive} // Set the checked attribute based on values.isActive
    onChange={(e) => setValues({ ...values, isActive: e.target.checked })}
  />
  <label className="form-check-label" htmlFor="isActive">
    Is Active
  </label>
</div>

</ModalBody>

          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="submit"
                className="btn btn-success m-1"
                id="add-btn"
                onClick={handleUpdate}
              >
                Update
              </button>
              <button
                type="button"
                className="btn btn-outline-danger m-1"
                onClick={() => setmodal_edit(false)}
              >
                Cancel
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>

      {/*Remove Modal*/}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        centered
      >
        <ModalHeader className="bg-light p-3">
          <span style={{ marginRight: "224px" }}>Remove Quotation Service</span>
          <Button
            type="button"
            onClick={() => {
              setmodal_delete(false);
            }}
            className="btn-close"
            aria-label="Close"
          ></Button>
        </ModalHeader>
        <form>
          <ModalBody>
            <div className="mt-2 text-center">
              <lord-icon
                src="https://cdn.lordicon.com/gsqxdxog.json"
                trigger="loop"
                colors="primary:#f7b84b,secondary:#f06548"
                style={{ width: "100px", height: "100px" }}
              ></lord-icon>
              <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                <h4>Are you sure ?</h4>
                <p className="text-muted mx-4 mb-0">
                  Are you Sure You want to Remove this Record ?
                </p>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="submit"
                className="btn btn-danger"
                id="add-btn"
                onClick={handleDelete}
              >
                Remove
              </button>
              <button
                type="button"
                className="btn btn-outline-danger"
                onClick={() => setmodal_delete(false)}
              >
                Close
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>
    </React.Fragment>
  );
};

export default Project;
