import axios from "axios";

export const createBannerMaster = async (values) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL_STONE}/api/auth/create/BannerMaster`,
    values
  );
};

export const removeBannerMaster = async (_id) => {
  return await axios.delete(
    `${process.env.REACT_APP_API_URL_STONE}/api/auth/remove/BannerMaster/${_id}`
  );
};

export const listBannerMaster = async () => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_STONE}/api/auth/list/BannerMaster`
  );
};

export const updateBannerMaster = async (_id, values) => {
  return await axios.put(
    `${process.env.REACT_APP_API_URL_STONE}/api/auth/update/BannerMaster/${_id}`,
    values
  );
};

export const getBannerMaster = async (_id) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_STONE}/api/auth/get/BannerMaster/${_id}`
  );
};
