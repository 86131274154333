import React from "react";
import { Navigate } from "react-router-dom";

//login
import Login from "../pages/Authentication/Login";
import Dashboard from "../pages/Dashboard/Dashboard";
import Services from "../pages/Services/Services";
import Project from "../pages/Projects/project";
import Profile from "../pages/Profile/Profile";
import CategoriesMaster from "../pages/CMS/CategoriesMaster";
import ContentMaster from "../pages/CMS/ContentMaster";
import ContactMaster from "../pages/Contact/ContactMaster";
import TeamMaster from "../pages/Teams/TeamMaster";
import OurDivison from "../pages/OurDivison/OurDivison";
import Banner from "../pages/Banner/Banner";
import AboutUs from "../pages/About/About";
import LoanApply from "../pages/Contact/LoanApply";
import FinancialService from "../pages/CMS/FinancialServices";
import Commercial from "../pages/PropertyMaster/Commercial";
import Residential from "../pages/PropertyMaster/Residential";
const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/services", component: <Services /> },
  { path: "/projects", component: <Project /> },
  { path: "/profile", component: <Profile /> },
  { path: "/categories-master", component: <CategoriesMaster /> },
  { path: "/content-master", component: <ContentMaster /> },
  { path: "/contact-master", component: <ContactMaster /> },
  { path: "/team-master", component: <TeamMaster /> },
  { path: "/our-divison", component: <OurDivison /> },
  { path: "/banner", component: <Banner /> },
  { path: "/about-us", component: <AboutUs /> },
  { path: "/FinancialService", component: <FinancialService /> },
  { path: "/loanapply", component: <LoanApply /> },
  { path: "/commercial-property-master", component: <Commercial /> },
  { path: "/residential-property-master", component: <Residential /> },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/contact-master" />,
  },
  { path: "*", component: <Navigate to="/contact-master" /> },
];

const publicRoutes = [
  // { path: "/dashboard", component: <DashboardCrm /> },
  { path: "/login", component: <Login /> },
];

export { authProtectedRoutes, publicRoutes };
