import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Collapse } from "reactstrap";

// Import Data
import navdata from "../LayoutMenuData";
//i18n
import { withTranslation } from "react-i18next";
import withRouter from "../../Components/Common/withRouter";

const VerticalLayout = (props) => {
  const [rateCard, setRateCard] = useState(false);
  const [locationSetup, setLocationSetup] = useState(false);
  const [application, setApplication] = useState(false);
  const [isParametersOpen, setIsParametersOpen] = useState(false);
  const [isApplicationOpen, setIsApplicationOpen] = useState(false);
  const [propCard, setPropCard] = useState(false);

  const navData = navdata().props.children;
  const path = props.router.location.pathname;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const initMenu = () => {
      const pathName = process.env.PUBLIC_URL + path;
      const ul = document.getElementById("navbar-nav");
      const items = ul.getElementsByTagName("a");
      let itemsArray = [...items]; // converts NodeList to Array
      removeActivation(itemsArray);
      let matchingMenuItem = itemsArray.find((x) => {
        return x.pathname === pathName;
      });
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    if (props.layoutType === "vertical") {
      initMenu();
    }
  }, [path, props.layoutType]);

  function activateParentDropdown(item) {
    item.classList.add("active");
    let parentCollapseDiv = item.closest(".collapse.menu-dropdown");

    if (parentCollapseDiv) {
      // to set aria expand true remaining
      parentCollapseDiv.classList.add("show");
      parentCollapseDiv.parentElement.children[0].classList.add("active");
      parentCollapseDiv.parentElement.children[0].setAttribute(
        "aria-expanded",
        "true"
      );
      if (parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown")) {
        parentCollapseDiv.parentElement
          .closest(".collapse")
          .classList.add("show");
        if (
          parentCollapseDiv.parentElement.closest(".collapse")
            .previousElementSibling
        )
          parentCollapseDiv.parentElement
            .closest(".collapse")
            .previousElementSibling.classList.add("active");
        if (
          parentCollapseDiv.parentElement
            .closest(".collapse")
            .previousElementSibling.closest(".collapse.menu-dropdown")
        ) {
          parentCollapseDiv.parentElement
            .closest(".collapse")
            .previousElementSibling.closest(".collapse.menu-dropdown")
            .classList.add("show");
          parentCollapseDiv.parentElement
            .closest(".collapse")
            .previousElementSibling.closest(".collapse.menu-dropdown")
            .previousElementSibling.classList.add("active");
        }
      }
      return false;
    }
    return false;
  }

  const removeActivation = (items) => {
    let actiItems = items.filter((x) => x.classList.contains("active"));

    actiItems.forEach((item) => {
      if (item.classList.contains("menu-link")) {
        if (!item.classList.contains("active")) {
          item.setAttribute("aria-expanded", false);
        }
        if (item.nextElementSibling) {
          item.nextElementSibling.classList.remove("show");
        }
      }
      if (item.classList.contains("nav-link")) {
        if (item.nextElementSibling) {
          item.nextElementSibling.classList.remove("show");
        }
        item.setAttribute("aria-expanded", false);
      }
      item.classList.remove("active");
    });
  };

  return (
    <React.Fragment>
      {/* menu Items */}
      <li className="menu-title">
        <span data-key="t-menu">Menu</span>
      </li>

      {/* <li className="nav-item">
        <Link className="nav-link menu-link" to="/dashboard">
          <span data-key="t-apps"> Dashboard </span>
        </Link>
      </li> */}
      {/* <li className="nav-item">
        <Link className="nav-link menu-link" to="/services">
          <span data-key="t-apps"> Services </span>
        </Link>
      </li>
      <li className="nav-item">
        <Link className="nav-link menu-link" to="/projects">
          <span data-key="t-apps"> Project </span>
        </Link>
      </li> */}

      <li className="nav-item">
        <Link
          className="nav-link menu-link"
          to="#"
          data-bs-toggle="collapse"
          onClick={() => {
            setRateCard(!rateCard);
          }}
        >
          <span data-key="t-apps"> CMS </span>
        </Link>
        <Collapse className="menu-dropdown" isOpen={rateCard}>
          <ul className="nav nav-sm flex-column test">
            <li className="nav-item">
              <Link className="nav-link menu-link" to="/categories-master">
                <span data-key="t-apps"> Categories Master</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link menu-link" to="/content-master">
                <span data-key="t-apps"> Content Master </span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link menu-link" to="/FinancialService">
                <span data-key="t-apps"> Financial Service </span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link menu-link" to="/our-divison">
                <span data-key="t-apps"> Our Divison </span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link menu-link" to="/banner">
                <span data-key="t-apps"> Banner </span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link menu-link" to="/about-us">
                <span data-key="t-apps"> About Us </span>
              </Link>
            </li>
          </ul>
        </Collapse>
      </li>

      <li className="nav-item">
        <Link
          className="nav-link menu-link"
          to="#"
          data-bs-toggle="collapse"
          onClick={() => {
            setPropCard(!propCard);
          }}
        >
          <span data-key="t-apps"> Property Master </span>
        </Link>
        <Collapse className="menu-dropdown" isOpen={propCard}>
          <ul className="nav nav-sm flex-column test">
            <li className="nav-item">
              <Link className="nav-link menu-link" to="/commercial-property-master">
                <span data-key="t-apps"> Commercial</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link menu-link" to="/residential-property-master">
                <span data-key="t-apps"> Residential </span>
              </Link>
            </li>
          </ul>
        </Collapse>
      </li>

      <li className="nav-item">
        <Link className="nav-link menu-link" to="/contact-master">
          <span data-key="t-apps"> Inquiry Master </span>
        </Link>
      </li>

      <li className="nav-item">
        <Link className="nav-link menu-link" to="/team-master">
          <span data-key="t-apps"> Team Master </span>
        </Link>
      </li>
      <li className="nav-item">
        <Link className="nav-link menu-link" to="/loanapply">
          <span data-key="t-apps"> Loan Inquiry </span>
        </Link>
      </li>

      {/* <li className="nav-item">
        <Link
          className="nav-link menu-link"
          to="#"
          data-bs-toggle="collapse"
          onClick={() => {
            setLocationSetup(!locationSetup);
          }}
        >
          <span data-key="t-apps"> Masters </span>
        </Link>
        <Collapse className="menu-dropdown" isOpen={locationSetup}>
          <ul className="nav nav-sm flex-column test">
            <li className="nav-item">
              <Link to="/employee" className="nav-link">
                Employee
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/agents" className="nav-link">
                Agents
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/document-checklist" className="nav-link">
                Document Checklist
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/process-checklist" className="nav-link">
                Process Checklist
              </Link>
            </li>
          </ul>
        </Collapse>
      </li> */}

      {/* <li className="nav-item">
        <Link className="nav-link menu-link" to="/application">
          <span data-key="t-apps"> Application </span>
        </Link>
      </li> */}
      {/* 
      <li className="nav-item">
        <Link
          className="nav-link menu-link"
          to="#"
          data-bs-toggle="collapse"
          onClick={() => {
            setApplication(!application);
          }}
        >
          <span data-key="t-apps"> Application </span>
        </Link>
        <Collapse className="menu-dropdown" isOpen={application}>
          <ul className="nav nav-sm flex-column test">
            <li className="nav-item">
              <Link className="nav-link menu-link" to="/application">
                <span data-key="t-apps">New Application</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link menu-link" to="/document-upload">
                <span data-key="t-apps">Document Upload</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link menu-link" to="/document-verification">
                <span data-key="t-apps">Document Verification</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link menu-link" to="/online-submission">
                <span data-key="t-apps">Online Submission</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link menu-link" to="/appointment-update">
                <span data-key="t-apps">Appointment Update</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link menu-link"
                to="/appointment-candidate-status"
              >
                <span data-key="t-apps">Appointment Candidate Status</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link menu-link" to="/visa-status">
                <span data-key="t-apps">Visa Status</span>
              </Link>
            </li>
          </ul>
        </Collapse>
      </li> */}

      {/* <li className="nav-item">
        <Link className="nav-link menu-link" to="/inquiry-details">
          <span data-key="t-apps"> Inquiry </span>
        </Link>
      </li>
  
      <li className="nav-item">
        <Link className="nav-link menu-link" to="/sold-details">
          <span data-key="t-apps"> Customer Details </span>
        </Link>
      </li> */}
    </React.Fragment>
  );
};

VerticalLayout.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(VerticalLayout));
