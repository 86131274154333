import axios from "axios";

export const creatService = async (values) => {
  console.log("image isss",Image);

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL_STONE}/api/create/addService`,
      values
    );
    console.log("Response", response);
    return response;
  } catch (error) {
    console.error("Error in createProcessStatus:", error);
    throw error; // Re-throw the error to propagate it to the calling code
  }
};
export const updateServices = async (_id,values) => {
  console.log("image isss",Image);
  try {
    
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL_STONE}/api/edit/editService/${_id}`,
      values
    );
    console.log("Response", response);
    return response;
  } catch (error) {
    console.error("Error in updateProcessStatus:", error);
    throw error; // Re-throw the error to propagate it to the calling code
  }
};

export const getServicesDetail = async () => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_STONE}/api/list/getAllService`
  );
};

export const deleteService =async(_id)=>{
    return await axios.delete(
        `${process.env.REACT_APP_API_URL_STONE}/api/delete/deleteServiceById/${_id}`,
    );
};

export const getServiceById = async (_id) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_STONE}/api/get/getServiceById/${_id}`
  );
};