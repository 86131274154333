import React, { useState, useEffect } from "react";
import {
  Input,
  Label,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import Select from "react-select";
import {
  updateProject, getProjectById, deleteProject, creatProject
} from "../../functions/Projects";
import axios from "axios";
import BreadCrumb from '../../Components/Common/BreadCrumb';
import DataTable from "react-data-table-component";
import { toast, ToastContainer } from "react-toastify";
import { Table } from "antd";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";


// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond';
// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);


const ContentMaster = () => {
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [filter, setFilter] = useState(true);
  const [_id, set_Id] = useState("");
  const [newCat, setNewCat] = useState(false);

  const initialState = {
    CategoryName: "",
    Heading: "",
    ContentImage: "",
    SubHeading: "",
    Title: "",
    Description: "",
    IsActive: false,
  };

  const [remove_id, setRemove_id] = useState("");

  //search and pagination state
  const [query, setQuery] = useState("");

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [values, setValues] = useState(initialState);
  const {
    CategoryName, ContentImage, Heading,
    SubHeading, Title
    , IsActive, Description

  } = values;

  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [pageNo, setPageNo] = useState(0);
  const [column, setcolumn] = useState();
  const [sortDirection, setsortDirection] = useState();
  const [image, setImage] = useState("");
  const [BGForm, setBGForm] = useState([]);
  const renderImage = (Image) => {
    const imageUrl = `${process.env.REACT_APP_API_URL_STONE}/${Image}`;
    return (
      <img
        src={imageUrl}
        alt="Image"
        style={{ width: '75px', height: '75px', padding: "5px" }}
      />
    );
  };
  const columns = [
    {
      name: "Process ID",
      selector: (row, index) => index + 1, // Generate auto-incremental ID based on row index
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "Category ",
      selector: (row) => row.CategoryName,
      sortable: true,
      sortField: "CategoryName",
      minWidth: "150px",
    },
    {
      name: "Heading ",
      selector: (row) => row.Heading,
      sortable: true,
      sortField: "Heading",
      minWidth: "150px",
    },
    {
      name: "Title ",
      selector: (row) => row.Title,
      sortable: true,
      sortField: "Title",
      minWidth: "150px",
    },
    {
      name: "IsActive",
      selector: (row) => (
        <span className={row.IsActive ? "badge bg-success" : "badge bg-danger"}>
          {row.IsActive ? "Active" : "Inactive"}
        </span>
      ),
      minWidth: "100px",
    },

    {
      name: "Action",
      selector: (row) => {
        return (
          <React.Fragment>
            <div className="d-flex gap-2">
              <div className="edit">
                <button
                  className="btn btn-sm btn-success edit-item-btn "
                  data-bs-toggle="modal"
                  data-bs-target="#showModal"
                  onClick={() => handleTog_edit(row._id)}
                >
                  Edit
                </button>
              </div>
              <div className="remove">
                <button
                  className="btn btn-sm btn-danger remove-item-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#deleteRecordModal"
                  onClick={() => tog_delete(row._id)}
                >
                  Remove
                </button>
              </div>
            </div>
          </React.Fragment>
        );
      },
      sortable: false,
      minWidth: "180px",
    },
  ];



  useEffect(() => {
    fetchContent();
  }, [pageNo, perPage, column, sortDirection, query, filter]);
  const [ServiceData, setServiceData] = useState([]);
  const [headingList, setHeadingList] = useState([]);


  const fetchCategoryNameList = async () => {
    try {
      setServiceData([]);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_STONE}/api/auth/getCategoryNameList`
      );

      console.log("My response", response.data);
      let data = response.data.map((service) => ({
        label: service.CategoryName,
        value: service.CategoryName,
        name: service.CategoryName,
      }));

      setServiceData(data);
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  }

  const fetchHeadingList = async (Hname) => {
    try {
      setHeadingList([]);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_STONE}/api/auth/getHeadingList`, { Hname }
      );

      console.log("My response", response.data);
      let data = response.data.map((service) => ({
        label: service.Heading,
        value: service.Heading,
        name: service.Heading,
      }));

      setHeadingList(data);
    } catch (error) {
      console.error("Error fetching Heading:", error);
    }
  }

  //   const fetchServices = async () => {
  //     try {
  //       const response = await axios.post(
  //         `${process.env.REACT_APP_API_URL_STONE}/api/list/getAllService`
  //       );

  //       console.log("My response", response.data);
  //       const data = response.map((service) => ({
  //         label: service.ServiceName,
  //         value: service._id,
  //         name: service.ServiceName,
  //       }));


  //     //   setServiceData(data);
  //     } catch (error) {
  //       console.error("Error fetching services:", error);
  //     }
  //   };

  const handleCategoryChange = (selectedOption) => {
    fetchHeadingList(selectedOption.name);
    console.log("Selected service:", selectedOption.name);
    if (selectedOption.name === "Add Category") {
      setNewCat(true)
    }
    else
      setNewCat(false)
    setValues({ ...values, CategoryName: selectedOption.name });
  };

  const handleHeadingChange = (selectedOption) => {
    console.log("Selected service:", selectedOption.name);
    setValues({ ...values, Heading: selectedOption.name });
  };




  const fetchContent = async () => {
    try {
      setLoading(true);
      let skip = (pageNo - 1) * perPage;
      if (skip < 0) {
        skip = 0;
      }

      // Initialize column and sortDirection with default values
      const defaultColumn = "CategoryName"; // Change this to your default column
      const defaultSortDirection = "asc"; // Change this to your default sort direction

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_STONE}/api/auth/list/Content`,
        {
          skip: skip,
          per_page: perPage,
          sorton: column || defaultColumn, // Use column or defaultColumn if column is undefined
          sortdir: sortDirection || defaultSortDirection, // Use sortDirection or defaultSortDirection if sortDirection is undefined
          match: query,
        }
      );

      console.log("Response:", response);

      console.log(Array.isArray(response));

      if (Array.isArray(response)) {
        setLoading(false);

        // Extract data for the current page
        const startIndex = skip;
        const endIndex = startIndex + perPage;
        const paginatedData = response.slice(startIndex, endIndex);

        setBGForm(paginatedData[0].data);
        setTotalRows(response[0].data.length);
      } else {
        // Handle non-200 status code or non-array data
        console.error("Invalid response:", response);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  const [modal_delete, setmodal_delete] = useState(false);

  const tog_delete = (_id) => {
    setmodal_delete(!modal_delete);
    setRemove_id(_id);
  };

  const [modal_edit, setmodal_edit] = useState(false);

  // const handlecheck = (e) => {
  //   console.log(e.target.checked);
  //   setValues({ ...values, IsActive: e.target.checked });
  // };

  const [modal_list, setModalList] = useState(false);

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log("no errors");
    }
  }, [formErrors, isSubmit]);

  // const loadBGForm = () => {
  //   listQuotation().then((res) => setBGForm(res));
  // };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleClick = async (e) => {
    e.preventDefault();
    let errors;
    // values.ContentImage = profilePhoto;
    // console.log("image to upload is", values.ContentImage);
    // if (!values.ContentImage) {
    //   toast.error("Please upload an image");
    //   return;
    // }
    try {
      console.log("before error", values)
      // Validate the form values
      errors = validate(values);

      // Set form errors and indicate submission
      setFormErrors(errors);
      setIsSubmit(true);
      console.log("after error", errors)
      if (Object.keys(errors).length === 0) {
        try {

          const formData = new FormData();
          formData.append("CategoryName", values.CategoryName);
          formData.append("Heading", values.Heading);
          formData.append("SubHeading", values.SubHeading);
          formData.append("ContentImage", values.ContentImage);
          formData.append("Description", values.Description);
          formData.append("Title", values.Title);
          formData.append("IsActive", values.IsActive);
          console.log("inner try", formData)
          //   Make the API request
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL_STONE}/api/auth/create/Content`,
            formData
          );

          if (response.isOk) {
            fetchContent();
            // Operation successful
            // setValues(initialState);
            setIsSubmit(false);
            setFormErrors({});

            setModalList(false);
            setNewCat(false);

          } else {
            // Operation failed
            // if (response.field === 1) {
            //   setFormErrors({ ServiceName: response.message });
            // }
            // fetchCategory();
          }
        } catch (error) {
          console.error("API Request Error:", error);
          setIsSubmit(false);

        }
        fetchContent(); setModalList(false);
        setNewCat(false)
      }
    } catch (error) {
      console.error("Handle Click Error:", error);
    }
  };

  // Add this function to your component
  const tog_list = () => {
    // fetchServices();
    seterrFileSize("");
    fetchCategoryNameList();
    setModalList(!modal_list);
    setNewCat(false);
    setValues(initialState);
    setIsSubmit(false);
    setProfilePhoto("");
  };

  const handleDelete = (e) => {
    e.preventDefault();
    console.log("Quotation services", remove_id);

    axios.delete(
      `${process.env.REACT_APP_API_URL_STONE}/api/auth/delete/deleteContent/${remove_id}`,
    ).then((res) => {
      console.log("deleted", res);
      setmodal_delete(!modal_delete);
      fetchContent();
    })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    console.log(values);

    try {
      let errors = validateEdit(values);
      setFormErrors(errors);
      setIsSubmit(true);

      if (Object.keys(errors).length === 0) {
        try {
          const formData = new FormData();
          formData.append("CategoryName", values.CategoryName);
          formData.append("Heading", values.Heading);
          formData.append("SubHeading", values.SubHeading);
          formData.append("ContentImage", values.ContentImage);
          formData.append("Description", values.Description);
          formData.append("Title", values.Title);
          formData.append("IsActive", values.IsActive);
          console.log("inner try", formData)
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL_STONE}/api/auth/UpdateContent/${_id}`,
            formData
          );
          if (response.isOk) {
            console.log(response);
            console.log("mod", modal_edit);
            fetchContent();
            // setValues(initialState);
            setFormErrors({}); setmodal_edit(false);
          }
          else {
            // Operation failed
          }
        }
        catch (error) {
          console.log(error);
        }
        fetchContent(); setmodal_edit(false);
      }
    }
    catch (error) {
      console.error("Handle Click Error:", error);
    }
  }

  const handleTog_edit = (_id) => {
    seterrFileSize("");
    setmodal_edit(!modal_edit);
    // handleCategoryChange();
    setProfilePhoto("");
    setIsSubmit(false);
    set_Id(_id);
    axios.get(
      `${process.env.REACT_APP_API_URL_STONE}/api/auth/getContentById/${_id}`,
    ).then((res) => {
      console.log("get", res);
      // console.log(res.plot_no);
      setValues({

        ...values,
        CategoryName: res.data.CategoryName,
        Heading: res.data.Heading,
        ContentImage: res.data.ContentImage,
        SubHeading: res.data.SubHeading,
        IsActive: res.data.IsActive,
        Description: res.data.Description,
        Title: res.data.Title
      });
    })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSort = (column, sortDirection) => {
    setcolumn(column.sortField);
    setsortDirection(sortDirection);
  };

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    // setPageNo(page);
    setPerPage(newPerPage);
  };
  const [errCat, seterrCat] = useState(false);
  const [errHea, seterrHea] = useState(false);
  // const [errSH, seterrSH] = useState(false);
  const [errTit, seterrTit] = useState(false);
  const [errBan, seterrBan] = useState(false);
  const [errDesc, seterrDesc] = useState(false);
  const [errFileSize, seterrFileSize] = useState("")

  const validate = (values) => {
    const errors = {};
    if (values.CategoryName === "") {
      errors.CategoryName = "Category Name is required";
      seterrCat(true);
    }
    else {
      seterrCat(false);
    }

    if (values.ContentImage === "") {
      errors.ContentImage = "Content Image is required";
      seterrBan(true);
    }
    else {
      seterrBan(false);
    }
    if (values.Heading === "") {
      errors.Heading = "Heading is required";
      seterrHea(true);
    }
    else {
      seterrHea(false);
    }
    // if (values.SubHeading === "") {
    //   errors.SubHeading = "SubHeading is required";
    //   seterrSH(true);
    // }
    // else {
    //   seterrSH(false);
    // }
    if (values.Description === "") {
      errors.Description = "Description is required";
      seterrDesc(true);
    }
    else {
      seterrDesc(false);
    }
    if (values.Title === "") {
      errors.Title = "Title is required";
      seterrTit(true);
    }
    else {
      seterrTit(false);
    }

    return errors;
  };

  const validateEdit = (values) => {
    const errors = {};
    console.log(values.CategoryName);
    if (values.CategoryName === "") {
      errors.CategoryName = "Category Name is required";
      seterrCat(true);
    } else {
      seterrCat(false);
    }
    if (values.ContentImage === "") {
      errors.ContentImage = "Content Image is required";
      seterrBan(true);
    } else {
      seterrBan(false);
    }
    if (values.Heading === "") {
      errors.Heading = "Heading is required";
      seterrHea(true);
    } else {
      seterrHea(false);
    }
    // if (values.SubHeading === "") {
    //   errors.SubHeading = "SubHeading is required";
    //   seterrSH(true);
    // } else {
    //   seterrSH(false);
    // }
    if (values.Description === "") {
      errors.Description = "Description is required";
      seterrDesc(true);
    } else {
      seterrDesc(false);
    }
    if (values.Title === "") {
      errors.Title = "Title is required";
      seterrTit(true);
    }
    else {
      seterrTit(false);
    }

    return errors;
  };
  const validClassCategoryName =
    errCat && isSubmit ? "form-control is-invalid" : "form-control";
  const validClassContentImage =
    errBan && isSubmit ? "form-control is-invalid" : "form-control";
  const validClassHeading =
    errHea && isSubmit ? "form-control is-invalid" : "form-control";
  // const validClassSubHeading =
  // errSH && isSubmit ? "form-control is-invalid" : "form-control";
  const validClassDescription =
    errDesc && isSubmit ? "form-control is-invalid" : "form-control";
  const validClassTitle =
    errTit && isSubmit ? "form-control is-invalid" : "form-control";

  const [selectedFiles, setselectedFiles] = useState([]);
  const [files, setFiles] = useState([]);

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  const handleFilter = (e) => {
    setFilter(e.target.checked);
  };
  const [profilePhoto, setProfilePhoto] = useState("");
  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    const maxSize = 2 * 1024 * 1024;
    if (file && file.size > maxSize) {
      seterrFileSize('File size exceeds the 2MB limit');
    } else {
      seterrFileSize('');
      setProfilePhoto(URL.createObjectURL(file));
      setValues({ ...values, [e.target.name]: file });
    }

    console.log(file);

  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    console.log(file);
    setImage(file);

  };
  document.title = "StoneRealEstate | CMS";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <BreadCrumb
            maintitle="Marwiz Setup"
            title="Content Master"
            pageTitle="CMS"
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  {/* <div className="h4 mb-0">Manage Quotation Services</div> */}
                  <Row className="g-4 mb-1">
                    <Col className="col-sm" lg={4} md={6} sm={6}>
                      <h2 className="card-title mb-0 fs-4 mt-2">Content Master</h2>
                    </Col>
                    <Col lg={4} md={6} sm={6}>
                      {/* <div className="text-end mt-1">
                        <Input
                          type="checkbox"
                          className="form-check-input"
                          name="filter"
                          value={filter}
                          defaultChecked={true}
                          onChange={handleFilter}
                        />
                        <Label className="form-check-label ms-2">Active</Label>
                      </div> */}
                    </Col>
                    <Col className="col-sm-auto" lg={4} md={12} sm={12}>
                      <div className="d-flex justify-content-sm-end">
                        <div>
                          <Button
                            color="success"
                            className="add-btn me-1"
                            onClick={() => tog_list()}
                            id="create-btn"
                          >
                            <i className="ri-add-line align-bottom me-1"></i>
                            Add
                          </Button>
                        </div>
                        <div className="search-box ms-2">
                          <input
                            // type="text"
                            className="form-control search"
                            placeholder="Search..."
                            onChange={(e) => setQuery(e.target.value)}
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div>
                    <div className="table-responsive table-card mt-1 mb-1 text-right">

                      <DataTable
                        columns={columns}
                        data={BGForm}
                        progressPending={loading}
                        sortServer
                        // onRowClicked={(row,e)=>{
                        //   debugger
                        // }}
                        onSort={(column, sortDirection, sortedRows) => {
                          handleSort(column, sortDirection);
                        }}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        paginationRowsPerPageOptions={[10, 50, 100, totalRows]}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                      />
                    </div>
                  </div>
                </CardBody>

              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* ADD FORM */}
      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
      >
        <ModalHeader
          className="bg-light p-3"
          toggle={() => {
            setModalList(false);
            setNewCat(false);
          }}
        >
          Add Content
          {/* <Button
            type="button"
            onClick={() => {
              setmodal_list(false);
            }}
            className="btn-close"
            aria-label="Close"
          ></Button> */}
        </ModalHeader>
        <form>
          <ModalBody>
            <label htmlFor="service-name" className="form-label">
              Category Name
              <span className="text-danger">*</span>
            </label>
            <div className="form-floating mb-3">
              <Select
                placeholder={CategoryName}
                id="CategoryName"
                value={CategoryName}
                options={ServiceData}
                onChange={handleCategoryChange}
              />
              {isSubmit && (
                <p className="text-danger">{formErrors.CategoryName}</p>
              )}

            </div>
            {newCat && <div className="form-floating mb-3">
              <input
                type="text"
                className={validClassCategoryName}
                placeholder="Category Name"
                required
                name="CategoryName"
                value={CategoryName}
                onChange={handleChange}
              />
              {isSubmit && (
                <p className="text-danger">{formErrors.CategoryName}</p>
              )}
              <label htmlFor="service-name" className="form-label">
                Category Name
                <span className="text-danger">*</span>
              </label>
            </div>}

            <label htmlFor="service-name" className="form-label">
              Heading
              <span className="text-danger">*</span>
            </label>
            <div className="form-floating mb-3">
              <Select
                placeholder={Heading}
                id="Heading"
                value={Heading}
                options={headingList}
                onChange={handleHeadingChange}
              />
              {isSubmit && (
                <p className="text-danger">{formErrors.Heading}</p>
              )}

            </div>

            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="SubHeading"
                required
                name="SubHeading"
                value={SubHeading}
                onChange={handleChange}
              />
              {/* {isSubmit && (
      <p className="text-danger">{formErrors.SubHeading}</p>
    )} */}
              <label htmlFor="service-name" className="form-label">
                SubHeading
              </label>
            </div>

            <div className="form-floating mb-3">
              <input
                type="text"
                className={validClassTitle}
                placeholder="Title"
                required
                name="Title"
                value={Title}
                onChange={handleChange}
              />
              {isSubmit && (
                <p className="text-danger">{formErrors.Title}</p>
              )}
              <label htmlFor="service-name" className="form-label">
                Title
                <span className="text-danger">*</span>
              </label>
            </div>
            <label htmlFor="service-name" className="form-label">
              Description
              <span className="text-danger">*</span>
            </label>
            <div className="form-floating mb-3">
              <CKEditor
                key={"Description" + _id}
                editor={ClassicEditor}
                data={Description}
                config={{
                  extraPlugins: [],
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setValues((prevValues) => ({
                    ...prevValues,
                    Description: data,
                  }));
                }}
              />
              {isSubmit && (
                <p className="text-danger">{formErrors.Description}</p>
              )}
            </div>

            <label htmlFor="service-name" className="form-label">
              Content Image
              <span className="text-danger">*</span>
            </label>
              <Input
                type="file"
                className={validClassContentImage}
                required
                id="ContentImage"
                name="ContentImage"
                accept=".jpg, .jpeg, .png"
                onChange={handlePhotoChange}
              />
              <div>
                <img
                  src={profilePhoto}
                  alt="Selected"
                  style={{ maxWidth: '200px', maxHeight: '200px' }}
                />
              {isSubmit && (
                <p className="text-danger">{errFileSize.length > 0 ? "" : formErrors.ContentImage}</p>
              )}
              <p className="text-danger">{errFileSize}</p>
            </div>


            <div className="mt-3">
              <Input
                type="checkbox"
                id="IsActive"
                label="Is Active"
                name="IsActive"
                value={IsActive}
                onChange={(e) => setValues({ ...values, IsActive: e.target.checked })}
                checked={IsActive}
              />
              <label className="me-2">Is Active</label>
            </div>
            {/* <div className="form-check form-switch"> */}
            {/* <input
      className={validClassIsActive}
      type="checkbox"
      id="IsActive"
      name="IsActive"
      checked={IsActive}
      onChange={(e) => setValues({ ...values, IsActive: e.target.checked })}
    /> */}
            {/* {isSubmit && (
      <p className="text-danger">{formErrors.IsActive}</p>
    )}
    <label className="form-check-label" htmlFor="IsActive">
      Is Active
    </label>
  </div> */}
          </ModalBody>

          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="submit"
                className="btn btn-success  m-1"
                id="add-btn"
                onClick={handleClick}
              >
                Submit
              </button>
              <button
                type="button"
                className="btn btn-outline-danger m-1"
                onClick={() => { setModalList(false); setNewCat(false) }}
              >
                Cancel
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>

      {/*Edit Modal*/}
      <Modal
        isOpen={modal_edit}
        toggle={() => {
          handleTog_edit();
        }}
        centered
      >
        <ModalHeader
          className="bg-light p-3"
          toggle={() => {
            setmodal_edit(false);
          }}
        >
          Edit Content
        </ModalHeader>
        <form>
          <ModalBody>
            <label htmlFor="service-name" className="form-label">
              Category Name
              <span className="text-danger">*</span>
            </label>
            <div className="form-floating mb-3">
              <Select
                placeholder={CategoryName}
                id="CategoryName"
                value={CategoryName}
                options={ServiceData}
                onChange={handleCategoryChange}
              />
              {isSubmit && (
                <p className="text-danger">{formErrors.CategoryName}</p>
              )}

            </div>
            {newCat && <div className="form-floating mb-3">
              <input
                type="text"
                className={validClassCategoryName}
                placeholder="Category Name"
                required
                name="CategoryName"
                value={CategoryName}
                onChange={handleChange}
              />
              {isSubmit && (
                <p className="text-danger">{formErrors.CategoryName}</p>
              )}
              <label htmlFor="service-name" className="form-label">
                Category Name
                <span className="text-danger">*</span>
              </label>
            </div>}

            <label htmlFor="service-name" className="form-label">
              Heading
              <span className="text-danger">*</span>
            </label>
            <div className="form-floating mb-3">
              <Select
                placeholder={Heading}
                id="Heading"
                value={Heading}
                options={headingList}
                onChange={handleHeadingChange}
              />
              {isSubmit && (
                <p className="text-danger">{formErrors.Heading}</p>
              )}

            </div>

            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="SubHeading"
                required
                name="SubHeading"
                value={SubHeading}
                onChange={handleChange}
              />
              {/* {isSubmit && (
      <p className="text-danger">{formErrors.SubHeading}</p>
    )} */}
              <label htmlFor="service-name" className="form-label">
                SubHeading
              </label>
            </div>

            <div className="form-floating mb-3">
              <input
                type="text"
                className={validClassTitle}
                placeholder="Title"
                required
                name="Title"
                value={values.Title}
                onChange={handleChange}
              />
              {isSubmit && (
                <p className="text-danger">{formErrors.Title}</p>
              )}
              <label htmlFor="service-name" className="form-label">
                Title
                <span className="text-danger">*</span>
              </label>
            </div>
            <label htmlFor="service-name" className="form-label">
              Description
              <span className="text-danger">*</span>
            </label>
            <div className="form-floating mb-3">
              <CKEditor
                editor={ClassicEditor}
                data={Description}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setValues((prevValues) => ({
                    ...prevValues,
                    Description: data,
                  }));
                }}
              />
              {isSubmit && (
                <p className="text-danger">{formErrors.Description}</p>
              )}

            </div>

            <label htmlFor="service-name" className="form-label">
              Content Image
              <span className="text-danger">*</span>
            </label>
            <div className="form-floating">
              <input
                type="file"
                className={validClassContentImage}
                required
                id="ContentImage"
                name="ContentImage"
                accept=".jpg, .jpeg, .png"
                onChange={handlePhotoChange}
              />
              <div>
                <img
                  src={`${process.env.REACT_APP_API_URL_STONE}/${values.ContentImage}`}
                  alt="Selected"
                  style={{ maxWidth: '200px', maxHeight: '200px' }}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = profilePhoto;
                  }}
                />
              </div>
              {isSubmit && (
                <p className="text-danger">{errFileSize.length > 0 ? "" : formErrors.ContentImage}</p>
              )}
              <p className="text-danger">{errFileSize}</p>
            </div>


            <div className="mt-3">
              <Input
                type="checkbox"
                id="IsActive"
                label="Is Active"
                name="IsActive"
                value={IsActive}
                onChange={(e) => setValues({ ...values, IsActive: e.target.checked })}
                checked={IsActive}
              />
              <label className="me-2">Is Active</label>
            </div>

          </ModalBody>

          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="submit"
                className="btn btn-success m-1"
                id="add-btn"
                onClick={handleUpdate}
              >
                Update
              </button>
              <button
                type="button"
                className="btn btn-outline-danger m-1"
                onClick={() => setmodal_edit(false)}
              >
                Cancel
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>

      {/*Remove Modal*/}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        centered
      >
        <ModalHeader className="bg-light p-3">
          <span style={{ marginRight: "224px" }}>Remove Content</span>
          <Button
            type="button"
            onClick={() => {
              setmodal_delete(false);
            }}
            className="btn-close"
            aria-label="Close"
          ></Button>
        </ModalHeader>
        <form>
          <ModalBody>
            <div className="mt-2 text-center">
              <lord-icon
                src="https://cdn.lordicon.com/gsqxdxog.json"
                trigger="loop"
                colors="primary:#f7b84b,secondary:#f06548"
                style={{ width: "100px", height: "100px" }}
              ></lord-icon>
              <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                <h4>Are you sure ?</h4>
                <p className="text-muted mx-4 mb-0">
                  Are you Sure You want to Remove this Record ?
                </p>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="submit"
                className="btn btn-danger"
                id="add-btn"
                onClick={handleDelete}
              >
                Remove
              </button>
              <button
                type="button"
                className="btn btn-outline-danger"
                onClick={() => setmodal_delete(false)}
              >
                Close
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>
    </React.Fragment>
  );
};

export default ContentMaster;
