module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "1046023766296-qs0cm8fjsp19dostpgbunefopsm00u72.apps.googleusercontent.com",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  api: {
    API_URL: "https://api-node.themesbrand.website",
  }
};