import axios from "axios";

export const createAboutUs = async (values) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL_STONE}/api/auth/create/AboutUs`,
    values
  );
};

export const removeAboutUs = async (_id) => {
  return await axios.delete(
    `${process.env.REACT_APP_API_URL_STONE}/api/auth/remove/AboutUs/${_id}`
  );
};

export const updateAboutUs = async (_id, values) => {
  return await axios.put(
    `${process.env.REACT_APP_API_URL_STONE}/api/auth/update/AboutUs/${_id}`,
    values
  );
};

export const getAboutUs = async (_id) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_STONE}/api/auth/get/AboutUs/${_id}`
  );
};

export const uploadImageGallery = async (body) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL_STONE}/api/auth/AboutUs/imageupload`,
    body
  );
};
