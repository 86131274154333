import React, { useState, useEffect } from "react";
import {
  Input,
  Label,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import Select from "react-select";
import {
  updateProject,getProjectById,deleteProject,creatProject
 } from "../../functions/Projects";
 import axios from "axios";  
 import BreadCrumb from '../../Components/Common/BreadCrumb';
import DataTable from "react-data-table-component";
import { toast, ToastContainer } from "react-toastify";
import { Table } from "antd";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";


// Import React FilePond
import { FilePond,  registerPlugin } from 'react-filepond';
// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);


const ContactMaster = () => {
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [filter, setFilter] = useState(true);
  const [_id, set_Id] = useState("");
  const [newCat, setNewCat] = useState(false);

  const initialState = {
    UserName: "",
    PhoneNumber: "",
    Email: "",
    Message: ""
  };

  const [remove_id, setRemove_id] = useState("");

  //search and pagination state
  const [query, setQuery] = useState("");

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [values, setValues] = useState(initialState);
  const {
    UserName, PhoneNumber, Email, Message
  } = values;

  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [pageNo, setPageNo] = useState(0);
  const [column, setcolumn] = useState();
  const [sortDirection, setsortDirection] = useState();
  const [image, setImage] = useState("");
  const [BGForm, setBGForm] = useState([]);
  const renderImage = (Image) => {
    const imageUrl = `${process.env.REACT_APP_API_URL_STONE}/${Image}`;
    return (
      <img
        src={imageUrl}
        alt="Image"
        style={{width:'75px',height:'75px',padding:"5px"}}
      />
    );
  };
  const columns = [
    {
      name: "Process ID",
      selector: (row, index) => index + 1, // Generate auto-incremental ID based on row index
      sortable: true,
      minWidth: "150px",
    },
    {
        name: "User Name ",
        selector: (row) => row.UserName,
        sortable: true,
        sortField: "UserName",
        minWidth: "150px",
      },
    {
      name: "Phone Number ",
      selector: (row) => row.PhoneNumber,
      sortable: true,
      sortField: "PhoneNumber",
      minWidth: "150px",
    },
    {
      name: "Email ",
      selector: (row) => row.Email,
      sortable: true,
      sortField: "Email",
      minWidth: "150px",
    },

    
    {
      name: "Action",
      selector: (row) => {
        return (
          <React.Fragment>
            <div className="d-flex gap-2">
              <div className="edit">
                <button
                  className="btn btn-sm btn-success edit-item-btn "
                  data-bs-toggle="modal"
                  data-bs-target="#showModal"
                  onClick={() => handleTog_edit(row._id)}
                >
                  View
                </button>
              </div>
              <div className="remove">
                <button
                  className="btn btn-sm btn-danger remove-item-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#deleteRecordModal"
                  onClick={() => tog_delete(row._id)}
                >
                  Remove
                </button>
              </div>
            </div>
          </React.Fragment>
        );
      },
      sortable: false,
      minWidth: "180px",
    },
  ];
  


  useEffect(() => {
    fetchContact();
  }, [pageNo, perPage, column, sortDirection, query, filter]);

  const fetchContact = async () => {
    try {
      setLoading(true);
      let skip = (pageNo - 1) * perPage;
      if (skip < 0) {
        skip = 0;
      }

      // Initialize column and sortDirection with default values
      const defaultColumn = "UserName"; // Change this to your default column
      const defaultSortDirection = "asc"; // Change this to your default sort direction

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_STONE}/api/auth/list/Contact`,
        {
          skip: skip,
          per_page: perPage,
          sorton: column || defaultColumn, // Use column or defaultColumn if column is undefined
          sortdir: sortDirection || defaultSortDirection, // Use sortDirection or defaultSortDirection if sortDirection is undefined
          match: query,
        }
      );

      console.log("Response:", response);

      console.log(Array.isArray(response));

      if (Array.isArray(response)) {
        setLoading(false);

        // Extract data for the current page
        const startIndex = skip;
        const endIndex = startIndex + perPage;
        const paginatedData = response.slice(startIndex, endIndex);

        setBGForm(paginatedData[0].data);
        setTotalRows(response[0].data.length);
      } else {
        // Handle non-200 status code or non-array data
        console.error("Invalid response:", response);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  const [modal_delete, setmodal_delete] = useState(false);

  const tog_delete = (_id) => {
    setmodal_delete(!modal_delete);
    setRemove_id(_id);
  };

  const [modal_edit, setmodal_edit] = useState(false);


  const [modal_list, setModalList] = useState(false);

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log("no errors");
    }
  }, [formErrors, isSubmit]);

  const tog_list = () => {
    setModalList(!modal_list);
    setNewCat(false);
    setValues(initialState);
    setIsSubmit(false);
  };

  const handleDelete = (e) => {
    e.preventDefault();
    console.log("Quotation services", remove_id);

    axios.delete(
        `${process.env.REACT_APP_API_URL_STONE}/api/auth/delete/Contact/${remove_id}`,
    ).then((res) => {
        console.log("deleted", res);
        setmodal_delete(!modal_delete);
        fetchContact();
      })
      .catch((err) => {
        console.log(err);
      });
  };


     
  const handleTog_edit = (_id) => {
    setmodal_edit(!modal_edit);
    setIsSubmit(false);
    set_Id(_id);
    axios.get(
        `${process.env.REACT_APP_API_URL_STONE}/api/auth/getContactById/${_id}`,
    ).then((res) => {
        console.log("get", res);
        // console.log(res.plot_no);
        setValues({
           
            ...values,
            UserName:res.data.UserName,
            PhoneNumber:res.data.PhoneNumber,
            Email:res.data.Email,
            Message :res.data.Message
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
 
  const handleSort = (column, sortDirection) => {
    setcolumn(column.sortField);
    setsortDirection(sortDirection);
  };

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    // setPageNo(page);
    setPerPage(newPerPage);
  };
 
  document.title = "StoneRealEstate | Inquiry Master";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <BreadCrumb
            maintitle="Marwiz Setup"
            title="Inquiry Master"
            pageTitle="Inquiry Master"
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  {/* <div className="h4 mb-0">Manage Quotation Services</div> */}
                  <Row className="g-4 mb-1">
                    <Col className="col-sm" lg={4} md={6} sm={6}>
                      <h2 className="card-title mb-0 fs-4 mt-2">Inquiry Master</h2>
                    </Col>
                    <Col lg={4} md={6} sm={6}>
                      {/* <div className="text-end mt-1">
                        <Input
                          type="checkbox"
                          className="form-check-input"
                          name="filter"
                          value={filter}
                          defaultChecked={true}
                          onChange={handleFilter}
                        />
                        <Label className="form-check-label ms-2">Active</Label>
                      </div> */}
                    </Col>
                    <Col className="col-sm-auto" lg={4} md={12} sm={12}>
                      <div className="d-flex justify-content-sm-end">
                        <div>
                          
                        </div>
                        <div className="search-box ms-2">
                          <input
                            // type="text"
                            className="form-control search"
                            placeholder="Search..."
                            onChange={(e) => setQuery(e.target.value)}
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div>
                    <div className="table-responsive table-card mt-1 mb-1 text-right">
                     
                       <DataTable
                        columns={columns}
                        data={BGForm}
                        progressPending={loading}
                        sortServer
                        // onRowClicked={(row,e)=>{
                        //   debugger
                        // }}
                        onSort={(column, sortDirection, sortedRows) => {
                          handleSort(column, sortDirection);
                        }}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        paginationRowsPerPageOptions={[10, 50, 100, totalRows]}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                      />
                    </div>
                  </div>
                </CardBody>
               
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/*View Modal*/}
      <Modal
        isOpen={modal_edit}
        toggle={() => {
          handleTog_edit();
        }}
        centered
      >
        <ModalHeader
          className="bg-light p-3"
          toggle={() => {
            setmodal_edit(false);
          }}
        >
          Inquiry Detail
        </ModalHeader>
        <form>
        <ModalBody>
        <p><span style={{fontWeight:"bold"}}>Name : </span> {values.UserName}</p>
        <p><span style={{fontWeight:"bold"}}>Number : </span> {values.PhoneNumber}</p>
        <p><span style={{fontWeight:"bold"}}>Email : </span> {values.Email}</p>
        <p><span style={{fontWeight:"bold"}}>Message : </span> </p><p>{values.Message}</p>

        </ModalBody>

          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-outline-danger m-1"
                onClick={() => setmodal_edit(false)}
              >
                Close
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>

      {/*Remove Modal*/}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        centered
      >
        <ModalHeader className="bg-light p-3">
          <span style={{ marginRight: "224px" }}>Remove Inquiry</span>
          <Button
            type="button"
            onClick={() => {
              setmodal_delete(false);
            }}
            className="btn-close"
            aria-label="Close"
          ></Button>
        </ModalHeader>
        <form>
          <ModalBody>
            <div className="mt-2 text-center">
              <lord-icon
                src="https://cdn.lordicon.com/gsqxdxog.json"
                trigger="loop"
                colors="primary:#f7b84b,secondary:#f06548"
                style={{ width: "100px", height: "100px" }}
              ></lord-icon>
              <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                <h4>Are you sure ?</h4>
                <p className="text-muted mx-4 mb-0">
                  Are you Sure You want to Remove this Record ?
                </p>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="submit"
                className="btn btn-danger"
                id="add-btn"
                onClick={handleDelete}
              >
                Remove
              </button>
              <button
                type="button"
                className="btn btn-outline-danger"
                onClick={() => setmodal_delete(false)}
              >
                Close
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>
    </React.Fragment>
  );
};

export default ContactMaster;
