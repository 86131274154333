import axios from "axios";

export const creatProject = async (values) => {
  console.log("image isss",Image);
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL_STONE}/api/create/addProject`,
      values
    );
    console.log("Response", response);
    return response;
  } catch (error) {
    console.error("Error in createProcessStatus:", error);
    throw error; // Re-throw the error to propagate it to the calling code
  }
};
export const updateProject = async (_id,values) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL_STONE}/api/edit/editProject/${_id}`,
      values
    );
    console.log("Response", response);
    return response;
  } catch (error) {
    console.error("Error in updateProcessStatus:", error);
    throw error; // Re-throw the error to propagate it to the calling code
  }
};

export const getProjectDetail = async () => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_STONE}/api/list/getAllProjects`
  );
};

export const deleteProject =async(_id)=>{
    return await axios.delete(
        `${process.env.REACT_APP_API_URL_STONE}/api/delete/deleteProjectById/${_id}`,
    );
};
export const getProjectById =async(_id)=>{
    return await axios.get(
        `${process.env.REACT_APP_API_URL_STONE}/api/get/getProjectById/${_id}`,
    );
};
// export const getProcessStatusById = async (_id) => {
//   return await axios.get(
//     `${process.env.REACT_APP_API_URL_STONE}/api/process-status-ById/${_id}`
//   );
// };