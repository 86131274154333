import React, { useState, useEffect } from "react";
import "../../assets/css/Commercial.css";
import moment from "moment-timezone";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Form,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Input,
  Row,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import axios from "axios";
import DataTable from "react-data-table-component";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";


const Commercial = () => {
    const [formErrors, setFormErrors] = useState({});
    const [showForm, setShowForm] = useState(false);
    const [updateForm, setUpdateForm] = useState(false);
    const [modal_delete, setmodal_delete] = useState(false);
    const [query, setQuery] = useState("");
    const [isSubmit, setIsSubmit] = useState(false);
    const [_id, set_Id] = useState("");
    const initialState = {
        AgentImage: "",
        PropertyImages: [],
        PropertyCategory: "",
        Video: "",
        Price: "",
        PropertyName: "",
        PropertyAddress: "",
        DealType: "",
        LandArea: "",
        Overview: "",
        AgentName: "",
        AgentNumber: "",
        AgentEmail: "",
        AgentCompany: "",
        PropertyType: "",
        Latitude: "",
        Longitude: "",
        IsActive: false
      };
    const [remove_id, setRemove_id] = useState("");
    const [values, setValues] = useState(initialState);
    const {
        AgentImage,
        PropertyImages,
        PropertyCategory,
        Video,
        Price,
        PropertyName,
        PropertyAddress,
        DealType,
        LandArea,
        Overview,
        AgentName,
        AgentNumber,
        AgentEmail,
        AgentCompany,
        PropertyType,
        Latitude,
        Longitude,
        IsActive
      } = values;
      const [loading, setLoading] = useState(false);
      const [totalRows, setTotalRows] = useState(0);
      const [perPage, setPerPage] = useState(10);
      const [pageNo, setPageNo] = useState(0);
      const [column, setcolumn] = useState();
      const [sortDirection, setsortDirection] = useState();
      const [BGForm, setBGForm] = useState([]);
      const [filter, setFilter] = useState(true);

      const handleFilter = (e) => {
        setFilter(e.target.checked);
      };

      const fetchContent = async () => {
        try {
          setLoading(true);
          let skip = (pageNo - 1) * perPage;
          if (skip < 0) {
            skip = 0;
          }
    
          // Initialize column and sortDirection with default values
          const defaultColumn = "CategoryName"; // Change this to your default column
          const defaultSortDirection = "asc"; // Change this to your default sort direction
    
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL_STONE}/api/auth/listComProperty`,
            {
              skip: skip,
              per_page: perPage,
              sorton: column || defaultColumn, // Use column or defaultColumn if column is undefined
              sortdir: sortDirection || defaultSortDirection, // Use sortDirection or defaultSortDirection if sortDirection is undefined
              match: query,
              IsActive: filter
            }
          );
    
          console.log("Response:", response);
    
          console.log(Array.isArray(response));
    
          if (Array.isArray(response)) {
            setLoading(false);
    
            // Extract data for the current page
            const startIndex = skip;
            const endIndex = startIndex + perPage;
            const paginatedData = response.slice(startIndex, endIndex);
    
            setBGForm(paginatedData[0].data);
            setTotalRows(response[0].data.length);
          } else {
            // Handle non-200 status code or non-array data
            console.error("Invalid response:", response);
            setLoading(false);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
          setLoading(false);
        }
      };

      useEffect(() => {
        fetchContent();
      }, [pageNo, perPage, column, sortDirection, query, filter]);

      const tog_delete = (_id) => {
        setmodal_delete(!modal_delete);
        setRemove_id(_id);
      };

      const handleChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
      };

      const handleClick = async (e) => {
        e.preventDefault();
        let errors;
        try {
          console.log("before error", values)
          // Validate the form values
          errors = validate(values);
    
          // Set form errors and indicate submission
          setFormErrors(errors);
          setIsSubmit(true);
          console.log("after error", errors)
          if (Object.keys(errors).length === 0) {
            try {
    
              const formData = new FormData();
              formData.append("AgentImage", values.AgentImage);
              formData.append("PropertyCategory", values.PropertyCategory);
              formData.append("Video", values.Video);
              formData.append("Price", values.Price);
              formData.append("PropertyName", values.PropertyName);
              formData.append("PropertyAddress", values.PropertyAddress);
              formData.append("DealType", values.DealType);
              formData.append("LandArea", values.LandArea);
              formData.append("Overview", values.Overview);
              formData.append("AgentName", values.AgentName);
              formData.append("AgentNumber", values.AgentNumber);
              formData.append("AgentEmail", values.AgentEmail);
              formData.append("AgentCompany", values.AgentCompany);
              formData.append("PropertyType", values.PropertyType);
              formData.append("Latitude", values.Latitude);
              formData.append("Longitude", values.Longitude);
              formData.append("IsActive", values.IsActive);
              values.PropertyImages.forEach((file) => {
                formData.append('PropertyImages', file);
              });

              const response = await axios.post(
                `${process.env.REACT_APP_API_URL_STONE}/api/auth/createComProperty`,
                formData,{
                    headers: {
                      'Content-Type': 'multipart/form-data',
                    },
                  }
              );
              
                fetchContent();
                setValues(initialState);
                setIsSubmit(false);
                setFormErrors({});
                setShowForm(false);

            } catch (error) {
              console.error("API Request Error:", error);
              setIsSubmit(false);
              setShowForm(false);
            }
            fetchContent();
          }
        } catch (error) {
          console.error("Handle Click Error:", error);
        }
      };

      const tog_list = () => {
        setShowForm(!showForm);
        setValues(initialState);
        setIsSubmit(false);
      };

      const handleDelete = (e) => {
        e.preventDefault();
        console.log("Quotation services", remove_id);
    
        axios.delete(
          `${process.env.REACT_APP_API_URL_STONE}/api/auth/deleteComProperty/${remove_id}`,
        ).then((res) => {
          console.log("deleted", res);
          setmodal_delete(!modal_delete);
          fetchContent();
        })
          .catch((err) => {
            console.log(err);
          });
      };

      const handleUpdate = async (e) => {
        e.preventDefault();
        console.log(values);
    
        try {
          let errors = validate(values);
          setFormErrors(errors);
          setIsSubmit(true);
    
          if (Object.keys(errors).length === 0) {
            try {
              const formData = new FormData();
              formData.append("AgentImage", values.AgentImage);
              formData.append("PropertyCategory", values.PropertyCategory);
              formData.append("Video", values.Video);
              formData.append("Price", values.Price);
              formData.append("PropertyName", values.PropertyName);
              formData.append("PropertyAddress", values.PropertyAddress);
              formData.append("DealType", values.DealType);
              formData.append("LandArea", values.LandArea);
              formData.append("Overview", values.Overview);
              formData.append("AgentName", values.AgentName);
              formData.append("AgentNumber", values.AgentNumber);
              formData.append("AgentEmail", values.AgentEmail);
              formData.append("AgentCompany", values.AgentCompany);
              formData.append("PropertyType", values.PropertyType);
              formData.append("Latitude", values.Latitude);
              formData.append("Longitude", values.Longitude);
              formData.append("IsActive", values.IsActive);
              values.PropertyImages.forEach((file) => {
                formData.append('PropertyImages', file);
              });

              const response = await axios.post(
                `${process.env.REACT_APP_API_URL_STONE}/api/auth/UpdateComProperty/${_id}`,
                formData
              );
              if (response.isOk) {
                fetchContent();
                setFormErrors({}); 
                setUpdateForm(false);
              }
            }
            catch (error) {
              console.log(error);
            }
            fetchContent(); 
            setUpdateForm(false);
          }
        }
        catch (error) {
          console.error("Handle Click Error:", error);
        }
      }

      const handleTog_edit = (_id) => {
        setUpdateForm(!updateForm);
        setIsSubmit(false);
        set_Id(_id);
        axios.post(
          `${process.env.REACT_APP_API_URL_STONE}/api/auth/ComProperty/${_id}`,
        ).then((res) => {
          console.log("get", res);
          // console.log(res.plot_no);
          setValues({
    
            ...values,
            AgentImage: res.data.AgentImage,
            PropertyImages: res.data.PropertyImages,
            PropertyCategory: res.data.PropertyCategory,
            Video: res.data.Video,
            Price: res.data.Price,
            PropertyName: res.data.PropertyName,
            PropertyAddress: res.data.PropertyAddress,
            DealType: res.data.DealType,
            LandArea: res.data.LandArea,
            Overview: res.data.Overview,
            AgentName: res.data.AgentName,
            AgentNumber: res.data.AgentNumber,
            AgentEmail: res.data.AgentEmail,
            AgentCompany: res.data.AgentCompany,
            PropertyType: res.data.PropertyType,
            Latitude: res.data.Latitude,
            Longitude: res.data.Longitude,
            IsActive: res.data.IsActive
          });
        })
          .catch((err) => {
            console.log(err);
          });
      };

      const handleSort = (column, sortDirection) => {
        setcolumn(column.sortField);
        setsortDirection(sortDirection);
      };
    
      const handlePageChange = (page) => {
        setPageNo(page);
      };
    
      const handlePerRowsChange = async (newPerPage, page) => {
        // setPageNo(page);
        setPerPage(newPerPage);
      };

      const [errAgentImg, seterrAgentImg] = useState(false);
      const [errProImg, seterrProImg] = useState(false);
      const [errProCat, seterrProCat] = useState(false);
      const [errVid, seterrVid] = useState(false);
      const [errPri, seterrPri] = useState(false);
      const [errProName, seterrProName] = useState(false);
      const [errProAdd, seterrProAdd] = useState(false);
      const [errDealType, seterrDealType] = useState(false);
      const [errLandAr, seterrLandAr] = useState(false);
      const [errOver, seterrOver] = useState(false);
      const [errAgentNm, seterrAgentNm] = useState(false);
      const [errAgentNum, seterrAgentNum] = useState(false);
      const [errAgentEm, seterrAgentEm] = useState(false);
      const [errAgentCom, seterrAgentCom] = useState(false);
      const [errProType, seterrProType] = useState(false);
      const [errLat, seterrLat] = useState(false);
      const [errLong, seterrLong] = useState(false);
      const [errFileSize, seterrFileSize] = useState("");
      const [errAgentFileSize, seterrAgentFileSize] = useState("");


      const validate = (values) => {
        const errors = {};
        if (values.AgentImage === "") {
          errors.AgentImage = "Agent Image is required";
          seterrAgentImg(true);
        }
        else {
            seterrAgentImg(false);
        }
    
        if (values.PropertyImages.length === 0) {
          errors.PropertyImages = "Property Image is required";
          seterrProImg(true);
        }
        else {
            seterrProImg(false);
        }
        if (values.PropertyCategory === "") {
          errors.PropertyCategory = "Property Category is required";
          seterrProCat(true);
        }
        else {
            seterrProCat(false);
        }
        if (values.Video === "") {
          errors.Video = "Video is required";
          seterrVid(true);
        }
        else {
            seterrVid(false);
        }
        if (values.Price === "") {
          errors.Price = "Price is required";
          seterrPri(true);
        }
        else {
            seterrPri(false);
        }
        if (values.PropertyName === "") {
            errors.PropertyName = "Property Name is required";
            seterrProName(true);
          }
          else {
            seterrProName(false);
          }
          if (values.PropertyAddress === "") {
            errors.PropertyAddress = "Property Address is required";
            seterrProAdd(true);
          }
          else {
            seterrProAdd(false);
          }
          if (values.DealType === "") {
            errors.DealType = "Deal Type is required";
            seterrDealType(true);
          }
          else {
            seterrDealType(false);
          }
          if (values.LandArea === "") {
            errors.LandArea = "Land Area is required";
            seterrLandAr(true);
          }
          else {
            seterrLandAr(false);
          }
          if (values.Overview === "") {
            errors.Overview = "Overview is required";
            seterrOver(true);
          }
          else {
            seterrOver(false);
          }
          if (values.AgentName === "") {
            errors.AgentName = "Agent Name is required";
            seterrAgentNm(true);
          }
          else {
            seterrAgentNm(false);
          }
          if (values.AgentNumber === "") {
            errors.AgentNumber = "Agent Number is required";
            seterrAgentNum(true);
          }
          else {
            seterrAgentNum(false);
          }
          if (values.AgentEmail === "") {
            errors.AgentEmail = "Agent Email is required";
            seterrAgentEm(true);
          }
          else {
            seterrAgentEm(false);
          }
          if (values.AgentCompany === "") {
            errors.AgentCompany = "Agent Company is required";
            seterrAgentCom(true);
          }
          else {
            seterrAgentCom(false);
          }
          if (values.PropertyType === "") {
            errors.PropertyType = "Property Type is required";
            seterrProType(true);
          }
          else {
            seterrProType(false);
          }
          if (values.Latitude === "") {
            errors.Latitude = "Latitude is required";
            seterrLat(true);
          }
          else {
            seterrLat(false);
          }
          if (values.Longitude === "") {
            errors.Longitude = "Longitude is required";
            seterrLong(true);
          }
          else {
            seterrLong(false);
          }
    
        return errors;
      };

      const validClassAgentImage =
      errAgentImg && isSubmit ? "form-control is-invalid" : "form-control";

       const validClassPropertyImages =
       errProImg && isSubmit ? "form-control is-invalid" : "form-control";

       const validClassPropertyCategory =
       errProCat && isSubmit ? "form-control is-invalid" : "form-control";

       const validClassVideo =
       errVid && isSubmit ? "form-control is-invalid" : "form-control";

       const validClassPrice =
       errPri && isSubmit ? "form-control is-invalid" : "form-control";

       const validClassPropertyName =
       errProName && isSubmit ? "form-control is-invalid" : "form-control";

       const validClassPropertyAddress =
       errProAdd && isSubmit ? "form-control is-invalid" : "form-control";

       const validClassDealType =
       errDealType && isSubmit ? "form-control is-invalid" : "form-control";

       const validClassLandArea =
       errLandAr && isSubmit ? "form-control is-invalid" : "form-control";

       const validClassOverview =
       errOver && isSubmit ? "form-control is-invalid" : "form-control";

       const validClassAgentName =
       errAgentNm && isSubmit ? "form-control is-invalid" : "form-control";

       const validClassAgentNumber =
       errAgentNum && isSubmit ? "form-control is-invalid" : "form-control";

       const validClassAgentEmail =
       errAgentEm && isSubmit ? "form-control is-invalid" : "form-control";

       const validClassAgentCompany =
       errAgentCom && isSubmit ? "form-control is-invalid" : "form-control";

       const validClassPropertyType =
       errProType && isSubmit ? "form-control is-invalid" : "form-control";

       const validClassLatitude =
       errLat && isSubmit ? "form-control is-invalid" : "form-control";

       const validClassLongitude =
       errLong && isSubmit ? "form-control is-invalid" : "form-control";

       const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        const maxSize = 2 * 1024 * 1024;
        let stat = false;
        for(let a=0; a<files.length; a++)
            {
                if(files.length > 0 && files[a].size > maxSize)
                    {
                        stat = true;
                        break;
                    }
            }
        if (stat) {
            seterrFileSize('File size exceeds the 2MB limit');
          } else {
            seterrFileSize('');
        let oldFileArray = PropertyImages;
        let newFileArray = [...oldFileArray,...files]
            setValues({...values,PropertyImages:newFileArray});
          }
      };

      const handleRemoveImage = (index) => {
        let newFileArray = values.PropertyImages.filter((_, i) => i !== index);
        setValues({...values,PropertyImages:newFileArray});
      };

      const handleAgentFileChange=(e)=>{
        const file = e.target.files[0];
    const maxSize = 2 * 1024 * 1024;
    if (file && file.size > maxSize) {
      seterrAgentFileSize('File size exceeds the 2MB limit');
    } else {
      seterrAgentFileSize('');
      setValues({ ...values, [e.target.name]: file });
    }
      }


  const col = [
    {
      name: "Property Name",
      cell: (row) => row.PropertyName,
      sortable: true,
      sortField: "PropertyName",
      minWidth: "150px",
    },

    {
      name: "Property Type",
      cell: (row) => row.PropertyType,
      sortable: true,
      sortField: "PropertyType",
      minWidth: "150px",
    },
    {
        name: "Agent Name",
        cell: (row) => row.AgentName,
        sortable: true,
        sortField: "AgentName",
        minWidth: "150px",
      },

    {
      name: "Action",
      selector: (row) => {
        return (
          <React.Fragment>
            <div className="d-flex gap-2">
              <div className="edit">
                <button
                  className="btn btn-sm btn-success edit-item-btn "
                  data-bs-toggle="modal"
                  data-bs-target="#showModal"
                  onClick={() => handleTog_edit(row._id)}
                >
                  Edit
                </button>
              </div>

              <div className="remove">
                <button
                  className="btn btn-sm btn-danger remove-item-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#deleteRecordModal"
                  onClick={() => tog_delete(row._id)}
                >
                  Remove
                </button>
              </div>
            </div>
          </React.Fragment>
        );
      },
      sortable: false,
      minWidth: "180px",
    },
  ];


  document.title = "Property Mster | Commercial";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            maintitle="Commercial"
            title="Commercial"
            pageTitle="Property Master"
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-1">
                    <Col className="col-sm" lg={4} md={6} sm={6}>
                      <h2 className="card-title mb-0 fs-4 mt-2">Commercial Property Master


                      </h2>
                    </Col>
                    <Col lg={4} md={6} sm={6}>
                      <div
                        style={{
                          display: showForm || updateForm ? "none" : "",
                        }}
                      >
                        <div className="text-end mt-1">
                          <Input
                            type="checkbox"
                            className="form-check-input"
                            name="filter"
                            value={filter}
                            defaultChecked={true}
                            onChange={handleFilter}
                          />
                          <Label className="form-check-label ms-2">
                            Active
                          </Label>
                        </div>
                      </div>
                    </Col>
                    <Col className="col-sm-auto" lg={4} md={12} sm={12}>
                      <div className="d-flex justify-content-sm-end">
                        {/* add btn */}
                        <div
                          style={{
                            display: showForm || updateForm ? "none" : "",
                          }}
                        >
                          <Row>
                            <Col lg={12}>
                              <div className="d-flex justify-content-sm-end">
                                <div>
                                  <Button
                                    color="success"
                                    className="add-btn me-1"
                                    onClick={tog_list}
                                  >
                                    <i className="ri-add-line align-bottom me-1"></i>
                                    Add
                                  </Button>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>

                        {/* update list btn */}

                        <div
                          style={{
                            display: showForm || updateForm ? "" : "none",
                          }}
                        >
                          <Row>
                            <Col lg={12}>
                              <div className="text-end">
                                <button
                                  className="btn bg-success text-light mb-3 "
                                  onClick={() => {
                                    setShowForm(false);
                                    setUpdateForm(false);
                                  }}
                                >
                                  <i class="ri-list-check align-bottom me-1"></i>{" "}
                                  List
                                </button>
                              </div>
                            </Col>
                          </Row>
                          {/* </div> */}
                        </div>

                        {/* search */}
                        <div
                          className="search-box ms-2"
                          style={{
                            display: showForm || updateForm ? "none" : "",
                          }}
                        >
                          <input
                            className="form-control search"
                            placeholder="Search..."
                            onChange={(e) => setQuery(e.target.value)}
                          />
                          <i className="ri-search-line search-icon "></i>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>

                {/* ADD FORM  */}
                <div
                  style={{
                    display: showForm && !updateForm ? "block" : "none",
                  }}
                >
                  <CardBody>
                    <React.Fragment>
                      <Col xxl={12}>
                        <Card className="">
                          {/* <PreviewCardHeader title="Billing Product Form" /> */}
                          <CardBody>
                            <div className="live-preview">
                              <Form>
                                <Row>
                                  <Col lg={4}>
                                    <div className="form-floating mb-3">
                                      <Input
                                        key={"PropertyCategory" + _id}
                                        type="text"
                                        className={validClassPropertyCategory}
                                        placeholder="Enter Property Category"
                                        required
                                        name="PropertyCategory"
                                        value={PropertyCategory}
                                        onChange={handleChange}
                                      />
                                      <Label>
                                      Property Category{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      {isSubmit && (
                                        <p className="text-danger">
                                          {formErrors.PropertyCategory}
                                        </p>
                                      )}
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <div className="form-floating mb-3">
                                      <Input
                                        key={"Price" + _id}
                                        type="text"
                                        className={validClassPrice}
                                        placeholder="Enter Price"
                                        required
                                        name="Price"
                                        value={Price}
                                        onChange={handleChange}
                                      />
                                      <Label>
                                      Price{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      {isSubmit && (
                                        <p className="text-danger">
                                          {formErrors.Price}
                                        </p>
                                      )}
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <div className="form-floating mb-3">
                                      <Input
                                        key={"PropertyName" + _id}
                                        type="text"
                                        className={validClassPropertyName}
                                        placeholder="Enter Property Name"
                                        required
                                        name="PropertyName"
                                        value={PropertyName}
                                        onChange={handleChange}
                                      />
                                      <Label>
                                      PropertyName{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      {isSubmit && (
                                        <p className="text-danger">
                                          {formErrors.PropertyName}
                                        </p>
                                      )}
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <div className="form-floating mb-3">
                                      <Input
                                        key={"PropertyAddress" + _id}
                                        type="text"
                                        className={validClassPropertyAddress}
                                        placeholder="Enter Property Address"
                                        required
                                        name="PropertyAddress"
                                        value={PropertyAddress}
                                        onChange={handleChange}
                                      />
                                      <Label>
                                      Property Address{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      {isSubmit && (
                                        <p className="text-danger">
                                          {formErrors.PropertyAddress}
                                        </p>
                                      )}
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <div className="form-floating mb-3">
                                      <Input
                                        key={"DealType" + _id}
                                        type="text"
                                        className={validClassDealType}
                                        placeholder="Enter Deal Type"
                                        required
                                        name="DealType"
                                        value={DealType}
                                        onChange={handleChange}
                                      />
                                      <Label>
                                      Deal Type{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      {isSubmit && (
                                        <p className="text-danger">
                                          {formErrors.DealType}
                                        </p>
                                      )}
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <div className="form-floating mb-3">
                                      <Input
                                        key={"LandArea" + _id}
                                        type="text"
                                        className={validClassLandArea}
                                        placeholder="Enter Land Area"
                                        required
                                        name="LandArea"
                                        value={LandArea}
                                        onChange={handleChange}
                                      />
                                      <Label>
                                      Land Area{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      {isSubmit && (
                                        <p className="text-danger">
                                          {formErrors.LandArea}
                                        </p>
                                      )}
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <div className="form-floating mb-3">
                                      <Input
                                        key={"AgentName" + _id}
                                        type="text"
                                        className={validClassAgentName}
                                        placeholder="Enter Agent Name"
                                        required
                                        name="AgentName"
                                        value={AgentName}
                                        onChange={handleChange}
                                      />
                                      <Label>
                                      Agent Name{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      {isSubmit && (
                                        <p className="text-danger">
                                          {formErrors.AgentName}
                                        </p>
                                      )}
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <div className="form-floating mb-3">
                                      <Input
                                        key={"AgentNumber" + _id}
                                        type="text"
                                        className={validClassAgentNumber}
                                        placeholder="Enter Agent Number"
                                        required
                                        name="AgentNumber"
                                        value={AgentNumber}
                                        onChange={handleChange}
                                      />
                                      <Label>
                                      Agent Number{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      {isSubmit && (
                                        <p className="text-danger">
                                          {formErrors.AgentNumber}
                                        </p>
                                      )}
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <div className="form-floating mb-3">
                                      <Input
                                        key={"AgentEmail" + _id}
                                        type="text"
                                        className={validClassAgentEmail}
                                        placeholder="Enter Agent Email"
                                        required
                                        name="AgentEmail"
                                        value={AgentEmail}
                                        onChange={handleChange}
                                      />
                                      <Label>
                                      Agent Email{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      {isSubmit && (
                                        <p className="text-danger">
                                          {formErrors.AgentEmail}
                                        </p>
                                      )}
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <div className="form-floating mb-3">
                                      <Input
                                        key={"AgentCompany" + _id}
                                        type="text"
                                        className={validClassAgentCompany}
                                        placeholder="Enter Agent Company"
                                        required
                                        name="AgentCompany"
                                        value={AgentCompany}
                                        onChange={handleChange}
                                      />
                                      <Label>
                                      Agent Company{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      {isSubmit && (
                                        <p className="text-danger">
                                          {formErrors.AgentCompany}
                                        </p>
                                      )}
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <div className="form-floating mb-3">
                                      <Input
                                        key={"PropertyType" + _id}
                                        type="text"
                                        className={validClassPropertyType}
                                        placeholder="Enter Property Type"
                                        required
                                        name="PropertyType"
                                        value={PropertyType}
                                        onChange={handleChange}
                                      />
                                      <Label>
                                      Property Type{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      {isSubmit && (
                                        <p className="text-danger">
                                          {formErrors.PropertyType}
                                        </p>
                                      )}
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <div className="form-floating mb-3">
                                      <Input
                                        key={"Latitude" + _id}
                                        type="text"
                                        className={validClassLatitude}
                                        placeholder="Enter Latitude"
                                        required
                                        name="Latitude"
                                        value={Latitude}
                                        onChange={handleChange}
                                      />
                                      <Label>
                                      Latitude{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      {isSubmit && (
                                        <p className="text-danger">
                                          {formErrors.Latitude}
                                        </p>
                                      )}
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <div className="form-floating mb-3">
                                      <Input
                                        key={"Longitude" + _id}
                                        type="text"
                                        className={validClassLongitude}
                                        placeholder="Enter Longitude"
                                        required
                                        name="Longitude"
                                        value={Longitude}
                                        onChange={handleChange}
                                      />
                                      <Label>
                                      Longitude{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      {isSubmit && (
                                        <p className="text-danger">
                                          {formErrors.Longitude}
                                        </p>
                                      )}
                                    </div>
                                  </Col>

                                  <Col lg={12}>
                                  <label>
                                             Overview{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                  <CKEditor
                                          key={"Overview" + _id}
                                          className={validClassOverview}
                                          editor={ClassicEditor}
                                          data={Overview}
                                          config={{
                                                   extraPlugins: [],
                                                  }}
                                          onChange={(event, editor) => {
                                          const data = editor.getData();
                                          setValues((prevValues) => ({
                                                      ...prevValues,
                                                      Overview: data,
                                                                     }));
                                                   }}
                                             />
                                      {isSubmit && (
                                        <p className="text-danger">
                                          {formErrors.Overview}
                                        </p>
                                      )}
                                  </Col>

                                  <Col lg={6}>
                                    <label>
                                    Property Images{" "}
                                      <span className="text-danger">*</span>
                                    </label>

                                    <Input
                                      key={"PropertyImages" + _id}
                                      type="file"
                                      multiple
                                      name="PropertyImages"
                                      className={validClassPropertyImages}
                                      // accept="Imagess/*"
                                      accept=".jpg, .jpeg, .png"
                                      onChange={handleFileChange}
                                    />
                                    {errFileSize.length > 0 && (
                                        <p className="text-danger">
                                        {errFileSize}
                                      </p>
                                    )}
                                    {isSubmit && (
                                        <p className="text-danger">
                                          {formErrors.PropertyImages}
                                        </p>
                                      )}
                                  </Col>
                                                                  <Col lg={12}>
                                                                      <div className="image-previews">
                                                                          {PropertyImages.length > 0 && PropertyImages.map((file, index) => (
                                                                              <div key={index} className="image-preview" style={{ position: "relative", width: "100px" }}>
                                                                                  <img
                                                                                      src={typeof file === "string" ? `${process.env.REACT_APP_API_URL_STONE}/${file}` : URL.createObjectURL(file)}
                                                                                      alt={`preview-${index}`}
                                                                                      style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                                                                                  />
                                                                                  <button onClick={() => handleRemoveImage(index)}>X</button>
                                                                              </div>
                                                                          ))}
                                                                      </div>
                                                                  </Col>

                                    <Col lg={6}>
                                    <label>
                                    Agent Image{" "}
                                      <span className="text-danger">*</span>
                                    </label>

                                    <Input
                                      key={"AgentImage" + _id}
                                      type="file"
                                      multiple
                                      name="AgentImage"
                                      className={validClassAgentImage}
                                      // accept="Imagess/*"
                                      accept=".jpg, .jpeg, .png"
                                      onChange={handleAgentFileChange}
                                    />
                                    {errAgentFileSize.length > 0 && (
                                        <p className="text-danger">
                                        {errAgentFileSize}
                                      </p>
                                    )}
                                    {isSubmit && (
                                        <p className="text-danger">
                                          {formErrors.AgentImage}
                                        </p>
                                      )}
                                  </Col>
                                  <Col lg={7}>
                                  {AgentImage && <img
                                        className="m-2"
                                        src={typeof AgentImage === "string" ? `${process.env.REACT_APP_API_URL_STONE}/${AgentImage}` : URL.createObjectURL(AgentImage)}
                                        alt="Profile"
                                        width="180"
                                        height="200"
                                      />}
                                  </Col>

                                  <Col lg={6} style={{marginTop:"50px"}}>
                                    <div className="form-floating mb-3">
                                      <Input
                                        key={"Video" + _id}
                                        type="text"
                                        className={validClassVideo}
                                        placeholder="Enter Video Link"
                                        required
                                        name="Video"
                                        value={Video}
                                        onChange={handleChange}
                                      />
                                      <Label>
                                      Video{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      {isSubmit && (
                                        <p className="text-danger">
                                          {formErrors.Video}
                                        </p>
                                      )}
                                    </div>
                                  </Col>

                                  <div className="mt-5">
                                    <Col lg={6}>
                                      <div className="form-check mb-2">
                                        <Input
                                          key={"IsActive_" + _id}
                                          type="checkbox"
                                          name="IsActive"
                                          value={IsActive}
                                          // onChange={handleCheck}
                                          onChange={(e) => {
                                            setValues({ ...values, [e.target.name]: e.target.checked })
                                          }}
                                          checked={IsActive}
                                        />
                                        <Label
                                          className="form-check-label"
                                          htmlFor="activeCheckBox"
                                        >
                                          Is Active
                                        </Label>
                                      </div>
                                    </Col>
                                  </div>

                                  {/* {loadingOption && (
                                    <div className="d-flex justify-content-center">
                                      <div
                                        className="spinner-border"
                                        role="status"
                                      >
                                        <span className="sr-only">
                                          Loading...
                                        </span>
                                      </div>
                                      <h6 className="p-2">
                                        Wait for a few seconds.This process
                                        might take some time.
                                      </h6>
                                    </div>
                                  )} */}

                                  <Col lg={12}>
                                    <div className="hstack gap-2 justify-content-end">
                                      <button
                                        type="submit"
                                        className="btn btn-success  m-1"
                                        id="add-btn"
                                        onClick={handleClick}
                                      >
                                        Submit
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-outline-danger m-1"
                                        onClick={() => {
                                            setShowForm(false);
                                          }}
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                  </Col>
                                </Row>
                              </Form>
                            </div>
                          </CardBody>{" "}
                        </Card>
                      </Col>
                    </React.Fragment>
                  </CardBody>
                </div>

                {/* UPDATE FORM  */}
                <div
                  style={{
                    display: !showForm && updateForm ? "block" : "none",
                  }}
                >
                  <CardBody>
                    <React.Fragment>
                      <Col xxl={12}>
                        <Card className="">
                        <CardBody>
                    <React.Fragment>
                      <Col xxl={12}>
                        <Card className="">
                          {/* <PreviewCardHeader title="Billing Product Form" /> */}
                          <CardBody>
                            <div className="live-preview">
                              {/* <Form> */}
                                <Row>
                                  <Col lg={4}>
                                    <div className="form-floating mb-3">
                                      <Input
                                        key={"PropertyCategory" + _id}
                                        type="text"
                                        className={validClassPropertyCategory}
                                        placeholder="Enter Property Category"
                                        required
                                        name="PropertyCategory"
                                        value={PropertyCategory}
                                        onChange={handleChange}
                                      />
                                      <Label>
                                      Property Category{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      {isSubmit && (
                                        <p className="text-danger">
                                          {formErrors.PropertyCategory}
                                        </p>
                                      )}
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <div className="form-floating mb-3">
                                      <Input
                                        key={"Price" + _id}
                                        type="text"
                                        className={validClassPrice}
                                        placeholder="Enter Price"
                                        required
                                        name="Price"
                                        value={Price}
                                        onChange={handleChange}
                                      />
                                      <Label>
                                      Price{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      {isSubmit && (
                                        <p className="text-danger">
                                          {formErrors.Price}
                                        </p>
                                      )}
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <div className="form-floating mb-3">
                                      <Input
                                        key={"PropertyName" + _id}
                                        type="text"
                                        className={validClassPropertyName}
                                        placeholder="Enter Property Name"
                                        required
                                        name="PropertyName"
                                        value={PropertyName}
                                        onChange={handleChange}
                                      />
                                      <Label>
                                      PropertyName{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      {isSubmit && (
                                        <p className="text-danger">
                                          {formErrors.PropertyName}
                                        </p>
                                      )}
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <div className="form-floating mb-3">
                                      <Input
                                        key={"PropertyAddress" + _id}
                                        type="text"
                                        className={validClassPropertyAddress}
                                        placeholder="Enter Property Address"
                                        required
                                        name="PropertyAddress"
                                        value={PropertyAddress}
                                        onChange={handleChange}
                                      />
                                      <Label>
                                      Property Address{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      {isSubmit && (
                                        <p className="text-danger">
                                          {formErrors.PropertyAddress}
                                        </p>
                                      )}
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <div className="form-floating mb-3">
                                      <Input
                                        key={"DealType" + _id}
                                        type="text"
                                        className={validClassDealType}
                                        placeholder="Enter Deal Type"
                                        required
                                        name="DealType"
                                        value={DealType}
                                        onChange={handleChange}
                                      />
                                      <Label>
                                      Deal Type{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      {isSubmit && (
                                        <p className="text-danger">
                                          {formErrors.DealType}
                                        </p>
                                      )}
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <div className="form-floating mb-3">
                                      <Input
                                        key={"LandArea" + _id}
                                        type="text"
                                        className={validClassLandArea}
                                        placeholder="Enter Land Area"
                                        required
                                        name="LandArea"
                                        value={LandArea}
                                        onChange={handleChange}
                                      />
                                      <Label>
                                      Land Area{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      {isSubmit && (
                                        <p className="text-danger">
                                          {formErrors.LandArea}
                                        </p>
                                      )}
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <div className="form-floating mb-3">
                                      <Input
                                        key={"AgentName" + _id}
                                        type="text"
                                        className={validClassAgentName}
                                        placeholder="Enter Agent Name"
                                        required
                                        name="AgentName"
                                        value={AgentName}
                                        onChange={handleChange}
                                      />
                                      <Label>
                                      Agent Name{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      {isSubmit && (
                                        <p className="text-danger">
                                          {formErrors.AgentName}
                                        </p>
                                      )}
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <div className="form-floating mb-3">
                                      <Input
                                        key={"AgentNumber" + _id}
                                        type="text"
                                        className={validClassAgentNumber}
                                        placeholder="Enter Agent Number"
                                        required
                                        name="AgentNumber"
                                        value={AgentNumber}
                                        onChange={handleChange}
                                      />
                                      <Label>
                                      Agent Number{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      {isSubmit && (
                                        <p className="text-danger">
                                          {formErrors.AgentNumber}
                                        </p>
                                      )}
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <div className="form-floating mb-3">
                                      <Input
                                        key={"AgentEmail" + _id}
                                        type="text"
                                        className={validClassAgentEmail}
                                        placeholder="Enter Agent Email"
                                        required
                                        name="AgentEmail"
                                        value={AgentEmail}
                                        onChange={handleChange}
                                      />
                                      <Label>
                                      Agent Email{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      {isSubmit && (
                                        <p className="text-danger">
                                          {formErrors.AgentEmail}
                                        </p>
                                      )}
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <div className="form-floating mb-3">
                                      <Input
                                        key={"AgentCompany" + _id}
                                        type="text"
                                        className={validClassAgentCompany}
                                        placeholder="Enter Agent Company"
                                        required
                                        name="AgentCompany"
                                        value={AgentCompany}
                                        onChange={handleChange}
                                      />
                                      <Label>
                                      Agent Company{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      {isSubmit && (
                                        <p className="text-danger">
                                          {formErrors.AgentCompany}
                                        </p>
                                      )}
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <div className="form-floating mb-3">
                                      <Input
                                        key={"PropertyType" + _id}
                                        type="text"
                                        className={validClassPropertyType}
                                        placeholder="Enter Property Type"
                                        required
                                        name="PropertyType"
                                        value={PropertyType}
                                        onChange={handleChange}
                                      />
                                      <Label>
                                      Property Type{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      {isSubmit && (
                                        <p className="text-danger">
                                          {formErrors.PropertyType}
                                        </p>
                                      )}
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <div className="form-floating mb-3">
                                      <Input
                                        key={"Latitude" + _id}
                                        type="text"
                                        className={validClassLatitude}
                                        placeholder="Enter Latitude"
                                        required
                                        name="Latitude"
                                        value={Latitude}
                                        onChange={handleChange}
                                      />
                                      <Label>
                                      Latitude{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      {isSubmit && (
                                        <p className="text-danger">
                                          {formErrors.Latitude}
                                        </p>
                                      )}
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <div className="form-floating mb-3">
                                      <Input
                                        key={"Longitude" + _id}
                                        type="text"
                                        className={validClassLongitude}
                                        placeholder="Enter Longitude"
                                        required
                                        name="Longitude"
                                        value={Longitude}
                                        onChange={handleChange}
                                      />
                                      <Label>
                                      Longitude{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      {isSubmit && (
                                        <p className="text-danger">
                                          {formErrors.Longitude}
                                        </p>
                                      )}
                                    </div>
                                  </Col>

                                  <Col lg={12}>
                                  <label>
                                             Overview{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                  <CKEditor
                                          key={"Overview" + _id}
                                          className={validClassOverview}
                                          editor={ClassicEditor}
                                          data={Overview}
                                          config={{
                                                   extraPlugins: [],
                                                  }}
                                          onChange={(event, editor) => {
                                          const data = editor.getData();
                                          setValues((prevValues) => ({
                                                      ...prevValues,
                                                      Overview: data,
                                                                     }));
                                                   }}
                                             />
                                      {isSubmit && (
                                        <p className="text-danger">
                                          {formErrors.Overview}
                                        </p>
                                      )}
                                  </Col>

                                  <Col lg={6}>
                                    <label>
                                    Property Images{" "}
                                      <span className="text-danger">*</span>
                                    </label>

                                    <Input
                                      key={"PropertyImages" + _id}
                                      type="file"
                                      multiple
                                      name="PropertyImages"
                                      className={validClassPropertyImages}
                                      // accept="Imagess/*"
                                      accept=".jpg, .jpeg, .png"
                                      onChange={handleFileChange}
                                    />
                                    {errFileSize.length > 0 && (
                                        <p className="text-danger">
                                        {errFileSize}
                                      </p>
                                    )}
                                    {isSubmit && (
                                        <p className="text-danger">
                                          {formErrors.PropertyImages}
                                        </p>
                                      )}
                                  </Col>
                                                                  <Col lg={12}>
                                                                      <div className="image-previews">
                                                                          {PropertyImages.length > 0 && PropertyImages.map((file, index) => (
                                                                              <div key={index} className="image-preview" style={{ position: "relative", width: "100px" }}>
                                                                                  <img
                                                                                      src={typeof file === "string" ? `${process.env.REACT_APP_API_URL_STONE}/${file}` : URL.createObjectURL(file)}
                                                                                      alt={`preview-${index}`}
                                                                                      style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                                                                                  />
                                                                                  <button onClick={() => handleRemoveImage(index)}>X</button>
                                                                              </div>
                                                                          ))}
                                                                      </div>
                                                                  </Col>

                                    <Col lg={6}>
                                    <label>
                                    Agent Image{" "}
                                      <span className="text-danger">*</span>
                                    </label>

                                    <Input
                                      key={"AgentImage" + _id}
                                      type="file"
                                      multiple
                                      name="AgentImage"
                                      className={validClassAgentImage}
                                      // accept="Imagess/*"
                                      accept=".jpg, .jpeg, .png"
                                      onChange={handleAgentFileChange}
                                    />
                                    {errAgentFileSize.length > 0 && (
                                        <p className="text-danger">
                                        {errAgentFileSize}
                                      </p>
                                    )}
                                    {isSubmit && (
                                        <p className="text-danger">
                                          {formErrors.AgentImage}
                                        </p>
                                      )}
                                  </Col>
                                  <Col lg={7}>
                                  {AgentImage && <img
                                        className="m-2"
                                        src={typeof AgentImage === "string" ? `${process.env.REACT_APP_API_URL_STONE}/${AgentImage}` : URL.createObjectURL(AgentImage)}
                                        alt="Profile"
                                        width="180"
                                        height="200"
                                      />}
                                  </Col>

                                  <Col lg={6} style={{marginTop:"50px"}}>
                                    <div className="form-floating mb-3">
                                      <Input
                                        key={"Video" + _id}
                                        type="text"
                                        className={validClassVideo}
                                        placeholder="Enter Video Link"
                                        required
                                        name="Video"
                                        value={Video}
                                        onChange={handleChange}
                                      />
                                      <Label>
                                      Video{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      {isSubmit && (
                                        <p className="text-danger">
                                          {formErrors.Video}
                                        </p>
                                      )}
                                    </div>
                                  </Col>

                                  <div className="mt-5">
                                    <Col lg={6}>
                                      <div className="form-check mb-2">
                                        <Input
                                          key={"IsActive_" + _id}
                                          type="checkbox"
                                          name="IsActive"
                                          value={IsActive}
                                          // onChange={handleCheck}
                                          onChange={(e) => {
                                            setValues({ ...values, [e.target.name]: e.target.checked })
                                          }}
                                          checked={IsActive}
                                        />
                                        <Label
                                          className="form-check-label"
                                          htmlFor="activeCheckBox"
                                        >
                                          Is Active
                                        </Label>
                                      </div>
                                    </Col>
                                  </div>

                                  {/* {loadingOption && (
                                    <div className="d-flex justify-content-center">
                                      <div
                                        className="spinner-border"
                                        role="status"
                                      >
                                        <span className="sr-only">
                                          Loading...
                                        </span>
                                      </div>
                                      <h6 className="p-2">
                                        Wait for a few seconds.This process
                                        might take some time.
                                      </h6>
                                    </div>
                                  )} */}

                                  <Col lg={12}>
                                    <div className="hstack gap-2 justify-content-end">
                                      <button
                                        type="submit"
                                        className="btn btn-success  m-1"
                                        id="add-btn"
                                        onClick={handleUpdate}
                                      >
                                        Submit
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-outline-danger m-1"
                                        onClick={() => {
                                            setUpdateForm(false);
                                          }}
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                  </Col>
                                </Row>
                              {/* </Form> */}
                            </div>
                          </CardBody>{" "}
                        </Card>
                      </Col>
                    </React.Fragment>
                  </CardBody>
                        </Card>
                      </Col>
                    </React.Fragment>
                  </CardBody>
                </div>

                {/* list */}
                <div
                  style={{
                    display: showForm || updateForm ? "none" : "block",
                  }}
                >
                  <CardBody>
                    <div>
                      <div className="table-responsive table-card mt-1 mb-1 text-right">
                        <DataTable
                          columns={col}
                          data={BGForm}
                          progressPending={loading}
                          sortServer
                          onSort={(column, sortDirection, sortedRows) => {
                            handleSort(column, sortDirection);
                          }}
                          pagination
                          paginationServer
                          paginationTotalRows={totalRows}
                          paginationRowsPerPageOptions={[
                            10,
                            50,
                            100,
                            totalRows,
                          ]}
                          onChangeRowsPerPage={handlePerRowsChange}
                          onChangePage={handlePageChange}
                        />
                      </div>
                    </div>
                  </CardBody>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/*Remove Modal*/}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        centered
      >
        <ModalHeader
          className="bg-light p-3"
          toggle={() => {
            setmodal_delete(!modal_delete);
          }}
        >
          <span style={{ marginRight: "210px" }}>Remove Division</span>
        </ModalHeader>

        <form>
          <ModalBody>
            <div className="mt-2 text-center">
              <lord-icon
                src="https://cdn.lordicon.com/gsqxdxog.json"
                trigger="loop"
                colors="primary:#f7b84b,secondary:#f06548"
                style={{ width: "100px", height: "100px" }}
              ></lord-icon>
              <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                <h4>Are you sure ?</h4>
                <p className="text-muted mx-4 mb-0">
                  Are you Sure You want to Remove this Record ?
                </p>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="submit"
                className="btn btn-danger"
                id="add-btn"
                onClick={handleDelete}
              >
                Remove
              </button>
              <button
                type="button"
                className="btn btn-outline-danger"
                onClick={() => setmodal_delete(false)}
              >
                Close
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>
    </React.Fragment>
  );
};

export default Commercial;
